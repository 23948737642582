import { Mapble } from "./Mapble";
import { Marker } from "./Marker"

export class Markable extends Mapble {
  constructor(map, id, visible) {
    super(map, id, visible)
    this.markers = []
    map.on('zoomend', () => {
      console.log('invalidating on zoomend')
      this.invalidate()
    })
    map.on('moveend', () => {
      console.log('invalidating on zoomend')
      this.invalidate()
    })
  }
  addMarker(marker, minZoom = 0, label = null, textSize = 15, colors = {labelText: '#000', labelHalo: '#fff'}) {
    const id = `${this.id}-marker${this.markers.length}`
    const _marker = new Marker(this.map, id, marker, minZoom, label, textSize, colors, this.visible)
    this.markers.push(_marker)
    return _marker
  }
  removeMarker(marker) {
    marker.remove()
    this.markers = this.markers.filter((o) => {
      return o != marker
    })
  }
  setVisible(visible) {
    super.setVisible(visible)
    this.markers.forEach((marker) => {
      marker.setVisible(visible)
    })
  }
  invalidate() {
    super.invalidate()
    this.markers.forEach((marker) => {
      marker.invalidate()
    })
  }
}