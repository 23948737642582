/**
 * @fileoverview gRPC-Web generated client stub for api
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: activity.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var waypoint_pb = require('./waypoint_pb.js')

var tracker_pb = require('./tracker_pb.js')

var notification_pb = require('./notification_pb.js')
const proto = {};
proto.api = require('./activity_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.api.ActivityServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.api.ActivityServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.CreateActivityRequest,
 *   !proto.api.CreateActivityResponse>}
 */
const methodDescriptor_ActivityService_CreateActivity = new grpc.web.MethodDescriptor(
  '/api.ActivityService/CreateActivity',
  grpc.web.MethodType.UNARY,
  proto.api.CreateActivityRequest,
  proto.api.CreateActivityResponse,
  /**
   * @param {!proto.api.CreateActivityRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.CreateActivityResponse.deserializeBinary
);


/**
 * @param {!proto.api.CreateActivityRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.CreateActivityResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.CreateActivityResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.ActivityServiceClient.prototype.createActivity =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.ActivityService/CreateActivity',
      request,
      metadata || {},
      methodDescriptor_ActivityService_CreateActivity,
      callback);
};


/**
 * @param {!proto.api.CreateActivityRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.CreateActivityResponse>}
 *     Promise that resolves to the response
 */
proto.api.ActivityServicePromiseClient.prototype.createActivity =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.ActivityService/CreateActivity',
      request,
      metadata || {},
      methodDescriptor_ActivityService_CreateActivity);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.GetActivityRequest,
 *   !proto.api.GetActivityResponse>}
 */
const methodDescriptor_ActivityService_GetActivity = new grpc.web.MethodDescriptor(
  '/api.ActivityService/GetActivity',
  grpc.web.MethodType.UNARY,
  proto.api.GetActivityRequest,
  proto.api.GetActivityResponse,
  /**
   * @param {!proto.api.GetActivityRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.GetActivityResponse.deserializeBinary
);


/**
 * @param {!proto.api.GetActivityRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.GetActivityResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.GetActivityResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.ActivityServiceClient.prototype.getActivity =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.ActivityService/GetActivity',
      request,
      metadata || {},
      methodDescriptor_ActivityService_GetActivity,
      callback);
};


/**
 * @param {!proto.api.GetActivityRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.GetActivityResponse>}
 *     Promise that resolves to the response
 */
proto.api.ActivityServicePromiseClient.prototype.getActivity =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.ActivityService/GetActivity',
      request,
      metadata || {},
      methodDescriptor_ActivityService_GetActivity);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.GetSharedActivityRequest,
 *   !proto.api.GetSharedActivityResponse>}
 */
const methodDescriptor_ActivityService_GetSharedActivity = new grpc.web.MethodDescriptor(
  '/api.ActivityService/GetSharedActivity',
  grpc.web.MethodType.UNARY,
  proto.api.GetSharedActivityRequest,
  proto.api.GetSharedActivityResponse,
  /**
   * @param {!proto.api.GetSharedActivityRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.GetSharedActivityResponse.deserializeBinary
);


/**
 * @param {!proto.api.GetSharedActivityRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.GetSharedActivityResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.GetSharedActivityResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.ActivityServiceClient.prototype.getSharedActivity =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.ActivityService/GetSharedActivity',
      request,
      metadata || {},
      methodDescriptor_ActivityService_GetSharedActivity,
      callback);
};


/**
 * @param {!proto.api.GetSharedActivityRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.GetSharedActivityResponse>}
 *     Promise that resolves to the response
 */
proto.api.ActivityServicePromiseClient.prototype.getSharedActivity =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.ActivityService/GetSharedActivity',
      request,
      metadata || {},
      methodDescriptor_ActivityService_GetSharedActivity);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.UpdateActivityRequest,
 *   !proto.api.UpdateActivityResponse>}
 */
const methodDescriptor_ActivityService_UpdateActivity = new grpc.web.MethodDescriptor(
  '/api.ActivityService/UpdateActivity',
  grpc.web.MethodType.UNARY,
  proto.api.UpdateActivityRequest,
  proto.api.UpdateActivityResponse,
  /**
   * @param {!proto.api.UpdateActivityRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.UpdateActivityResponse.deserializeBinary
);


/**
 * @param {!proto.api.UpdateActivityRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.UpdateActivityResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.UpdateActivityResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.ActivityServiceClient.prototype.updateActivity =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.ActivityService/UpdateActivity',
      request,
      metadata || {},
      methodDescriptor_ActivityService_UpdateActivity,
      callback);
};


/**
 * @param {!proto.api.UpdateActivityRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.UpdateActivityResponse>}
 *     Promise that resolves to the response
 */
proto.api.ActivityServicePromiseClient.prototype.updateActivity =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.ActivityService/UpdateActivity',
      request,
      metadata || {},
      methodDescriptor_ActivityService_UpdateActivity);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.DeleteActivityRequest,
 *   !proto.api.DeleteActivityResponse>}
 */
const methodDescriptor_ActivityService_DeleteActivity = new grpc.web.MethodDescriptor(
  '/api.ActivityService/DeleteActivity',
  grpc.web.MethodType.UNARY,
  proto.api.DeleteActivityRequest,
  proto.api.DeleteActivityResponse,
  /**
   * @param {!proto.api.DeleteActivityRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.DeleteActivityResponse.deserializeBinary
);


/**
 * @param {!proto.api.DeleteActivityRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.DeleteActivityResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.DeleteActivityResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.ActivityServiceClient.prototype.deleteActivity =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.ActivityService/DeleteActivity',
      request,
      metadata || {},
      methodDescriptor_ActivityService_DeleteActivity,
      callback);
};


/**
 * @param {!proto.api.DeleteActivityRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.DeleteActivityResponse>}
 *     Promise that resolves to the response
 */
proto.api.ActivityServicePromiseClient.prototype.deleteActivity =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.ActivityService/DeleteActivity',
      request,
      metadata || {},
      methodDescriptor_ActivityService_DeleteActivity);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.AddTrackerRequest,
 *   !proto.api.AddTrackerResponse>}
 */
const methodDescriptor_ActivityService_AddTracker = new grpc.web.MethodDescriptor(
  '/api.ActivityService/AddTracker',
  grpc.web.MethodType.UNARY,
  proto.api.AddTrackerRequest,
  proto.api.AddTrackerResponse,
  /**
   * @param {!proto.api.AddTrackerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.AddTrackerResponse.deserializeBinary
);


/**
 * @param {!proto.api.AddTrackerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.AddTrackerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.AddTrackerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.ActivityServiceClient.prototype.addTracker =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.ActivityService/AddTracker',
      request,
      metadata || {},
      methodDescriptor_ActivityService_AddTracker,
      callback);
};


/**
 * @param {!proto.api.AddTrackerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.AddTrackerResponse>}
 *     Promise that resolves to the response
 */
proto.api.ActivityServicePromiseClient.prototype.addTracker =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.ActivityService/AddTracker',
      request,
      metadata || {},
      methodDescriptor_ActivityService_AddTracker);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.RemoveTrackerRequest,
 *   !proto.api.RemoveTrackerResponse>}
 */
const methodDescriptor_ActivityService_RemoveTracker = new grpc.web.MethodDescriptor(
  '/api.ActivityService/RemoveTracker',
  grpc.web.MethodType.UNARY,
  proto.api.RemoveTrackerRequest,
  proto.api.RemoveTrackerResponse,
  /**
   * @param {!proto.api.RemoveTrackerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.RemoveTrackerResponse.deserializeBinary
);


/**
 * @param {!proto.api.RemoveTrackerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.RemoveTrackerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.RemoveTrackerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.ActivityServiceClient.prototype.removeTracker =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.ActivityService/RemoveTracker',
      request,
      metadata || {},
      methodDescriptor_ActivityService_RemoveTracker,
      callback);
};


/**
 * @param {!proto.api.RemoveTrackerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.RemoveTrackerResponse>}
 *     Promise that resolves to the response
 */
proto.api.ActivityServicePromiseClient.prototype.removeTracker =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.ActivityService/RemoveTracker',
      request,
      metadata || {},
      methodDescriptor_ActivityService_RemoveTracker);
};


module.exports = proto.api;

