/**
 * @fileoverview gRPC-Web generated client stub for api
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: waypoint.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.api = require('./waypoint_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.api.WaypointServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.api.WaypointServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.CreateWaypointRequest,
 *   !proto.api.CreateWaypointResponse>}
 */
const methodDescriptor_WaypointService_CreateWaypoint = new grpc.web.MethodDescriptor(
  '/api.WaypointService/CreateWaypoint',
  grpc.web.MethodType.UNARY,
  proto.api.CreateWaypointRequest,
  proto.api.CreateWaypointResponse,
  /**
   * @param {!proto.api.CreateWaypointRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.CreateWaypointResponse.deserializeBinary
);


/**
 * @param {!proto.api.CreateWaypointRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.CreateWaypointResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.CreateWaypointResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.WaypointServiceClient.prototype.createWaypoint =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.WaypointService/CreateWaypoint',
      request,
      metadata || {},
      methodDescriptor_WaypointService_CreateWaypoint,
      callback);
};


/**
 * @param {!proto.api.CreateWaypointRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.CreateWaypointResponse>}
 *     Promise that resolves to the response
 */
proto.api.WaypointServicePromiseClient.prototype.createWaypoint =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.WaypointService/CreateWaypoint',
      request,
      metadata || {},
      methodDescriptor_WaypointService_CreateWaypoint);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.ListWaypointRequest,
 *   !proto.api.ListWaypointResponse>}
 */
const methodDescriptor_WaypointService_ListWaypoint = new grpc.web.MethodDescriptor(
  '/api.WaypointService/ListWaypoint',
  grpc.web.MethodType.UNARY,
  proto.api.ListWaypointRequest,
  proto.api.ListWaypointResponse,
  /**
   * @param {!proto.api.ListWaypointRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.ListWaypointResponse.deserializeBinary
);


/**
 * @param {!proto.api.ListWaypointRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.ListWaypointResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.ListWaypointResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.WaypointServiceClient.prototype.listWaypoint =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.WaypointService/ListWaypoint',
      request,
      metadata || {},
      methodDescriptor_WaypointService_ListWaypoint,
      callback);
};


/**
 * @param {!proto.api.ListWaypointRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.ListWaypointResponse>}
 *     Promise that resolves to the response
 */
proto.api.WaypointServicePromiseClient.prototype.listWaypoint =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.WaypointService/ListWaypoint',
      request,
      metadata || {},
      methodDescriptor_WaypointService_ListWaypoint);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.UpdateWaypointRequest,
 *   !proto.api.UpdateWaypointResponse>}
 */
const methodDescriptor_WaypointService_UpdateWaypoint = new grpc.web.MethodDescriptor(
  '/api.WaypointService/UpdateWaypoint',
  grpc.web.MethodType.UNARY,
  proto.api.UpdateWaypointRequest,
  proto.api.UpdateWaypointResponse,
  /**
   * @param {!proto.api.UpdateWaypointRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.UpdateWaypointResponse.deserializeBinary
);


/**
 * @param {!proto.api.UpdateWaypointRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.UpdateWaypointResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.UpdateWaypointResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.WaypointServiceClient.prototype.updateWaypoint =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.WaypointService/UpdateWaypoint',
      request,
      metadata || {},
      methodDescriptor_WaypointService_UpdateWaypoint,
      callback);
};


/**
 * @param {!proto.api.UpdateWaypointRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.UpdateWaypointResponse>}
 *     Promise that resolves to the response
 */
proto.api.WaypointServicePromiseClient.prototype.updateWaypoint =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.WaypointService/UpdateWaypoint',
      request,
      metadata || {},
      methodDescriptor_WaypointService_UpdateWaypoint);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.DeleteWaypointRequest,
 *   !proto.api.DeleteWaypointResponse>}
 */
const methodDescriptor_WaypointService_DeleteWaypoint = new grpc.web.MethodDescriptor(
  '/api.WaypointService/DeleteWaypoint',
  grpc.web.MethodType.UNARY,
  proto.api.DeleteWaypointRequest,
  proto.api.DeleteWaypointResponse,
  /**
   * @param {!proto.api.DeleteWaypointRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.DeleteWaypointResponse.deserializeBinary
);


/**
 * @param {!proto.api.DeleteWaypointRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.DeleteWaypointResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.DeleteWaypointResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.WaypointServiceClient.prototype.deleteWaypoint =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.WaypointService/DeleteWaypoint',
      request,
      metadata || {},
      methodDescriptor_WaypointService_DeleteWaypoint,
      callback);
};


/**
 * @param {!proto.api.DeleteWaypointRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.DeleteWaypointResponse>}
 *     Promise that resolves to the response
 */
proto.api.WaypointServicePromiseClient.prototype.deleteWaypoint =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.WaypointService/DeleteWaypoint',
      request,
      metadata || {},
      methodDescriptor_WaypointService_DeleteWaypoint);
};


module.exports = proto.api;

