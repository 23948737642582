<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  <div class="d-flex vh-100 flex-column">
    <NavBar :brand="navBrand"/>
    <router-view @navBrand="onNavBrand"/>
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue'

export default {
  name: 'App',
  components: {
    NavBar,
  },
  data() {
    return {
      navBrand: null,
    }
  },
  mounted: function () {
  },
  methods: {
    onNavBrand: function (brand) {
      this.navBrand = brand
    },
  },
}
</script>

<style lang="scss">
@import '~bootstrap/scss/bootstrap';
body {
  margin: 0px;
  padding: 0px;
}
</style>
