export function durationString(sec) {
  if (sec < 60) {
    return `${Math.round(sec)}秒`
  } else if (sec < 3600) {
    return `${Math.round(sec / 60)}分鐘`
  } else if (sec < 86400) {
    return `${(sec / 3600).toFixed(1)}小時`
  } else {
    return `${(sec / 86400).toFixed(1)}天`
  }
}

export function isAdminConsole() {
  return window.location.hostname == 'inreach.me' || window.location.hostname == 'localhost'
}
