import { Mapble } from "./Mapble"

export class Marker extends Mapble {
  constructor(map, id, marker, minZoom, label, textSize, colors, visible) {
    super(map, id, visible)
    this.marker = marker
    this.minZoom = minZoom
    this.textSize = textSize
    this.colors = colors
    // if (pulseSize) {
    //   this._addPulseImage(id+'_pulse', pulseSize, dotColor)
    //   this._createPulse(id+'_pulse')
    // }
    if (label) {
      this.geojson = this._createLabel(id + '_label', label)
    }
  }
  remove() {
    console.log(`removing marker: ${this.id}`)
    this.layers.forEach((obj) => {
      const { id } = obj
      this.map.removeLayer(id)
      this.map.removeSource(id)
    })
    this.layers = []
    this.marker.remove()
  }
  invalidate() {
    super.invalidate()
    this.layers.forEach((obj) => {
      const { id } = obj
      this.map.moveLayer(id)
    })
    if (this.isVisible()) {
      this.marker.addTo(this.map)
    } else {
      this.marker.remove()
    }
  }
  setLngLat(lngLat) {
    this.marker.setLngLat(lngLat)
    this.layers.forEach((obj) => {
      const {data} = obj
      switch (data.features[0].geometry.type) {
        case 'Point':
          data.features[0].geometry.coordinates = lngLat
          break
        case 'Polygon':
          data.features[0].geometry.coordinates = this._createCircleCoordinates(this.radius)
          break
      }
    })
    this.invalidate()
    return this
  }
  getLngLat() {
    const { lat, lng } = this.marker.getLngLat()
    return [lng, lat]
  }
  setRadius(radius, color) {
    var found = false
    this.layers.forEach((obj) => {
      const {data} = obj
      switch (data.features[0].geometry.type) {
        case 'Polygon':
          data.features[0].geometry.coordinates = this._createCircleCoordinates(radius)
          found = true
          break
      }
    })
    if (found) {
      this.invalidate()
    } else {
      this._createCircle(this.id + '_geofence', radius, color)
    }
    this.radius = radius
  }
  setLabel(label) {
    if (this.geojson) {
      this.geojson.features[0].properties.description = label
      this.invalidate()
    } else {
      this.geojson = this._createLabel(this.id, this.marker, label)
    }
    return this
  }
  setPopup(popup) {
    this.marker.setPopup(popup)
    return this
  }
  isVisible() {
    const zoom = this.map.getZoom()
    const bbox = this.map.getBounds()
    return this.visible && zoom >= this.minZoom && bbox.contains(this.getLngLat())
  }
  _createLabel(id, label) {
    const geojson = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {
            description: label,
          },
          geometry: {
            type: 'Point',
            coordinates: this.getLngLat(),
          }
        },
      ]
    }
    this.addLayer(id, 'geojson', geojson, {
      type: 'symbol',
      'layout': {
        'text-field': ['get', 'description'],
        'text-variable-anchor': ['top', 'left', 'right'],
        'text-radial-offset': 0.5,
        'text-justify': 'auto',
        'text-font': [
          'Open Sans Bold',
          'Arial Unicode MS Bold'
        ],
        'text-size': this.textSize,
      },
      'paint': {
        'text-color': this.colors.labelText,
        'text-halo-color': this.colors.labelHalo,
        'text-halo-width': 1,
        'text-halo-blur': 1,
      },
    })
    return geojson
  }
  _createCircleCoordinates(radius) {
    const points = 64
    const center = this.getLngLat()
    var coords = {
        latitude: center[1],
        longitude: center[0]
    }

    var km = radius/1000

    var ret = []
    var distanceX = km/(111.320*Math.cos(coords.latitude*Math.PI/180))
    var distanceY = km/110.574

    var theta, x, y
    for(var i=0; i<points; i++) {
        theta = (i/points)*(2*Math.PI)
        x = distanceX*Math.cos(theta)
        y = distanceY*Math.sin(theta)

        ret.push([coords.longitude+x, coords.latitude+y])
    }
    ret.push(ret[0])
    return [ret]
  }
  _createCircle(id, radius, color) {
    const geojson = {
      type: "FeatureCollection",
      features: [{
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: this._createCircleCoordinates(radius)
        }
      }]
    };
    this.addLayer(id, 'geojson', geojson, {
      type: 'fill',
      layout: {},
      paint: {
        "fill-color": color,
        // "fill-opacity": 0.3,
      },
    })
    return geojson
  }
  _createPulse(id) {
    const geojson = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: this.getLngLat(),
          }
        },
      ]
    }
    this.addLayer(id, 'geojson', geojson, {
      type: 'symbol',
      'layout': {
        'icon-image': id,
        'icon-allow-overlap': true,
      },
    })
    return geojson
  }
  _addPulseImage(id, size, dotColor = null) {
    // This implements `StyleImageInterface`
    // to draw a pulsing dot icon on the map.
    var map = this.map
    const pulsingDot = {
      width: size,
      height: size,
      data: new Uint8Array(size * size * 4),

      // When the layer is added to the map,
      // get the rendering context for the map canvas.
      onAdd: function () {
        const canvas = document.createElement('canvas');
        canvas.width = this.width;
        canvas.height = this.height;
        this.context = canvas.getContext('2d');
      },

      // Call once before every frame where the icon will be used.
      render: function () {
        const duration = 1000;
        const t = (performance.now() % duration) / duration;

        const radius = (size / 2) * 0.3;
        const outerRadius = (size / 2) * 0.7 * t + radius;
        const context = this.context;

        // Draw the outer circle.
        context.clearRect(0, 0, this.width, this.height);
        context.beginPath();
        context.arc(
          this.width / 2,
          this.height / 2,
          outerRadius,
          0,
          Math.PI * 2
        );
        context.fillStyle = `rgba(255, 200, 200, ${1 - t})`;
        context.fill();

        if (dotColor) {
          // Draw the inner circle.
          context.beginPath();
          context.arc(
            this.width / 2,
            this.height / 2,
            radius,
            0,
            Math.PI * 2
          );
          context.fillStyle = dotColor;
          context.strokeStyle = 'white';
          context.lineWidth = 2 + 4 * (1 - t);
          context.fill();
          context.stroke();
        }

        // Update this image's data with data from the canvas.
        this.data = context.getImageData(
          0,
          0,
          this.width,
          this.height
        ).data;

        // Continuously repaint the map, resulting
        // in the smooth animation of the dot.
        map.triggerRepaint();

        // Return `true` to let the map know that the image was updated.
        return true;
      }
    };
    this.map.addImage(id, pulsingDot, { pixelRatio: 2 })
  }
}
