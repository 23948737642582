import { Tracker } from "./Tracker"
import mapboxgl from 'mapbox-gl'
import { Markable } from "./Markable"

const trackerColors = [
  {
    badgeText: '#000000',
    line: '#ff0000',
    outline: '#ffffff',
    labelText: '#000000',
    labelHalo: '#ff0000',
  },
  {
    badgeText: '#000000',
    line: '#ff9900',
    outline: '#ffffff',
    labelText: '#000000',
    labelHalo: '#ff9900',
  },
  {
    badgeText: '#000000',
    line: '#ffff00',
    outline: '#ffffff',
    labelText: '#000000',
    labelHalo: '#ffff00',
  },
  {
    badgeText: '#000000',
    line: '#0cad00',
    outline: '#ffffff',
    labelText: '#000000',
    labelHalo: '#0cad00',
  },
  {
    badgeText: '#000000',
    line: '#00ffd5',
    outline: '#000000',
    labelText: '#000000',
    labelHalo: '#00ffd5',
  },
  {
    badgeText: '#000000',
    line: '#00bbff',
    outline: '#ffffff',
    labelText: '#000000',
    labelHalo: '#00bbff',
  },
  {
    badgeText: '#ffffff',
    line: '#0040ff',
    outline: '#ffffff',
    labelText: '#ffffff',
    labelHalo: '#0040ff',
  },
  {
    badgeText: '#ffffff',
    line: '#d400ff',
    outline: '#ffffff',
    labelText: '#ffffff',
    labelHalo: '#d400ff',
  },
  {
    badgeText: '#ffffff',
    line: '#ff0077',
    outline: '#ffffff',
    labelText: '#ffffff',
    labelHalo: '#ff0077',
  },
]

export class Activity extends Markable {
  constructor(map, id, name, visible) {
    super(map, id, visible)
    this.name = name
    this.startTime = null
    this.stopTime = null
    this.notification = null
    this.waypoints = []
    this.callbacks = {}
    this.trackers = {}
  }
  on(event, callback) {
    this.callbacks[event] = callback
  }
  setVisible(visible) {
    super.setVisible(visible)
    Object.values(this.trackers).forEach((tracker) => {      
      tracker.setVisible(visible)
    })
  }
  setDisplayName(id, name) {
    const tracker = this._getTracker(id)
    if (!tracker.name) tracker.setDisplayName(name)
  }
  addHeartBeat(id, beat) {
    this._getTracker(id).addHeartBeat(beat)
  }
  addWaypoint(wpt, dragged, editable = false) {
    const {name, lat, lng, radius} = wpt
    const lngLat = [lng, lat]
    const el = document.createElement('div')
    el.className = 'waypoint'
    const marker = new mapboxgl.Marker(el, {anchor: 'bottom', draggable: true})
      .setLngLat(lngLat)
      .setPopup(this._createPopup(wpt, editable))
    wpt.marker = this.addMarker(marker, 0, name)
    wpt.marker.setRadius(radius, 'rgba(0, 0, 255, 0.2)')
    marker.on('drag', () => {
      const {lat, lng} = marker.getLngLat()
      const lngLat = [lng, lat]
      wpt.marker.setLngLat(lngLat)
    })
    marker.on('dragend', () => {
      if (dragged) {
        dragged(wpt)
      } else {
        wpt.marker.setLngLat(lngLat)
      }
    })
    this.waypoints.push(wpt)
    this.invalidate()
  }
  updateWaypoint(wpt, editable = false) {
    const {name, lat, lng, radius} = wpt
    const lngLat = [lng, lat]
    const el = document.createElement('div')
    el.className = 'waypoint'
    for (var i = 0; i < this.waypoints.length; i++) {
      if (this.waypoints[i].id == wpt.id) {
        const marker = this.waypoints[i].marker
        marker.setRadius(radius, 'rgba(0, 0, 255, 0.2)')
        marker.setLabel(name)
        marker.setLngLat(lngLat)
        marker.setPopup(this._createPopup(wpt, editable))
        wpt.marker = marker
        this.waypoints[i] = wpt
        return true
      }
    }
    return false
  }
  restoreWaypoint(wpt) {
    this.waypoints.forEach((o) => {
      if (o.id == wpt.id) {
        const {lat, lng} = o
        const lngLat = [lng, lat]
        o.marker.setLngLat(lngLat)
      }
    })
  }
  deleteWaypoint(wpt) {
    this.waypoints.forEach((o) => {
      if (o.id == wpt.id) {
        this.removeMarker(o.marker)
      }
    })
    this.waypoints = this.waypoints.filter((o) => {
      return o.id != wpt.id
    })
  }
  addPosition(id, pos) {
    this._getTracker(id).addPosition(pos)
  }
  start(id, pos) {
    this._getTracker(id).start(pos)
  }
  stop(id, pos) {
    this._getTracker(id).stop(pos)
  }
  addMessage(id, msg) {
    this._getTracker(id).addMessage(msg)
  }
  getBounds() {
    var bounds = null
    Object.values(this.trackers).forEach((tracker) => {
      const b = tracker.getBounds()
      if (!b) return
      if (bounds) {
        bounds = bounds.extend(b)
      } else {
        bounds = new mapboxgl.LngLatBounds(b.getSouthWest(), b.getNorthEast())
      }
    })
    this.waypoints.forEach((obj) => {
      const {lat, lng} = obj
      const coord = new mapboxgl.LngLat(lng, lat)
      if (bounds) {
        bounds = bounds.extend(coord)
      } else {
        bounds = new mapboxgl.LngLatBounds(coord, coord)
      }
    })
    return bounds
  }
  addTracker(t) {
    const {id, preferredName, displayName} = t
    const length = Object.keys(this.trackers).length
    const tracker = new Tracker(this.map, id, preferredName, displayName, trackerColors[length % trackerColors.length], this.visible)
    this.trackers[id] = tracker
    const callback = this.callbacks['newtracker']
    if (callback) callback(tracker)
    return tracker
  }
  _getTracker(id) {
    var tracker = this.trackers[id]
    if (!tracker) {
      tracker = this.addTracker({id})
    }
    return tracker
  }
  _createPopup(wpt, editable) {
    const {id, name, lat, lng, radius} = wpt
    const radiusHtml = radius ? `<br/>推播: <a href="https://zh.wikipedia.org/zh-tw/%E5%9C%B0%E7%90%86%E5%9B%B4%E6%A0%8F" target="_blank">半徑${radius.toFixed(0)}公尺</a>` : '<br/>推播: 關閉'
    const edit = editable ? `<div style="text-align: center; padding-top: 10px;"><button type="button" onclick="editWaypoint('${id}')">編輯</button></div>` : ''
    return new mapboxgl.Popup({offset: 10}).setHTML(`<div class="popup">航點: ${name}<br/>位置: ${lat.toFixed(6)},${lng.toFixed(6)}${radiusHtml}${edit}</div>`)
  }
}
