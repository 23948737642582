import mapboxgl from 'mapbox-gl'
import { Markable } from './Markable'

export class Track extends Markable {
  constructor(map, id, colors, visible) {
    super(map, id, visible)
    this.times = []
    this.coordinates = []
    this.positions = []
    this.geojson = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {
            coordTimes: this.times
          },
          geometry: {
            type: 'LineString',
            coordinates: this.coordinates
          }
        },
      ]
    }
    this.addLayer(id+'_outline', 'geojson', this.geojson, {
      type: 'line',
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': colors.outline,
        'line-width': 6,
        'line-opacity': 0.5,
      }
    })
    this.addLayer(id+'_line', 'geojson', this.geojson, {
      type: 'line',
      layout: {
        'line-join': 'round',
        'line-cap': 'round'
      },
      paint: {
        'line-color': colors.line,
        'line-width': 3,
        'line-opacity': 0.7,
      }
    })
    this.addLayer(id+'_directions', 'geojson', this.geojson, {
      type: 'symbol',
      'minzoom': 12,
      layout: {
        'symbol-placement': 'line',
        'symbol-spacing': 50,
        'icon-image': 'arrow',
        'icon-size': 0.5,
        'icon-rotate': 90,
        // 'icon-opacity': 0.5,
        'icon-rotation-alignment': 'map',
        'icon-allow-overlap': true,
        'icon-ignore-placement': true,
      },
      paint: {}
    })
  }
  getBounds() {
    return this.bounds
  }
  startTrack(pos) {
    this.geojson.features.push({
      type: 'Feature',
      properties: {
        coordTimes: this.times
      },
      geometry: {
        type: 'LineString',
        coordinates: this.coordinates
      }
    })
    this.start = pos
    this.addPosition(pos)
  }
  stopTrack(pos) {
    const {time} = pos
    if (this.start.marker) {
      this.start.marker.setPopup(
        new mapboxgl.Popup({offset: 10}).setHTML(`<div class="popup">開始: ${this.start.time.toLocaleString()}<br/>結束: ${time.toLocaleString()}<br/>位置: ${this.start.lat.toFixed(6)},${this.start.lng.toFixed(6)}<br/>高度: ${this.start.alt}</div>`)
      )
    }
    this.stop = pos
    this.stop.marker = this.addStopMaker()
    this.addPosition(pos)
  }
  addPosition(pos) {
    const {time, lat, lng, alt} = pos
    for (var i = this.positions.length; i >= 0; i--) {
      if (i == 0 || this.positions[i-1].time <= time) {
        this.positions.splice(i, 0, pos)
        this.times.splice(i, 0, time)
        this.coordinates.splice(i, 0, [lng, lat, alt])
        break
      }
    }
    if (this.coordinates.length == 2) {
      this.start.marker = this.addStartMaker()
    }
    if (this.coordinates.length > 1) {
      this.latest.marker = this.addPositionMarker()
    }
    this.latest = this.positions[this.positions.length-1]
    const coord = new mapboxgl.LngLat(lng, lat)
    if (this.bounds) {
      this.bounds = this.bounds.extend(coord)
    } else {
      this.bounds = new mapboxgl.LngLatBounds(coord, coord)
    }
    this.invalidate()
    return this.latest
  }
  addStartMaker() {
    const {time, lat, lng, alt} = this.start
    const popup = new mapboxgl.Popup({offset: 10}).setHTML(`<div class="popup">開始: ${time.toLocaleString()}<br/>位置: ${lat.toFixed(6)},${lng.toFixed(6)}<br/>高度: ${alt}</div>`)
    const el = document.createElement('div')
    el.className = 'start-tracking'
    const marker = new mapboxgl.Marker(el)
      .setLngLat([lng, lat])
      .setPopup(popup)
    return this.addMarker(marker)
  }
  addStopMaker() {
    const {time, lat, lng, alt} = this.stop
    const popup = new mapboxgl.Popup({offset: 10}).setHTML(`<div class="popup">開始: ${this.start.time.toLocaleString()}<br/>結束: ${time.toLocaleString()}<br/>位置: ${lat.toFixed(6)},${lng.toFixed(6)}<br/>高度: ${alt}</div>`)
    const el = document.createElement('div')
    el.className = 'stop-tracking'
    const marker = new mapboxgl.Marker(el)
      .setLngLat([lng, lat])
      .setPopup(popup)
    return this.addMarker(marker)
  }
  addPositionMarker() {
    const {time, lat, lng, alt} = this.latest
    const popup = new mapboxgl.Popup({offset: 10}).setHTML(`<div class="popup">時間: ${time.toLocaleString()}<br/>位置: ${lat.toFixed(6)},${lng.toFixed(6)}<br/>高度: ${alt}</div>`)
    const el = document.createElement('div')
    el.className = 'position'
    const marker = new mapboxgl.Marker(el)
      .setLngLat([lng, lat])
      .setPopup(popup)
    return this.addMarker(marker, 15)
  }
}
