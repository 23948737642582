/**
 * @fileoverview gRPC-Web generated client stub for api
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: notification.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.api = require('./notification_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.api.NotificationServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.api.NotificationServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.PrepareLINENotificationRequest,
 *   !proto.api.PrepareLINENotificationResponse>}
 */
const methodDescriptor_NotificationService_PrepareLINENotification = new grpc.web.MethodDescriptor(
  '/api.NotificationService/PrepareLINENotification',
  grpc.web.MethodType.UNARY,
  proto.api.PrepareLINENotificationRequest,
  proto.api.PrepareLINENotificationResponse,
  /**
   * @param {!proto.api.PrepareLINENotificationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.PrepareLINENotificationResponse.deserializeBinary
);


/**
 * @param {!proto.api.PrepareLINENotificationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.PrepareLINENotificationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.PrepareLINENotificationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.NotificationServiceClient.prototype.prepareLINENotification =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.NotificationService/PrepareLINENotification',
      request,
      metadata || {},
      methodDescriptor_NotificationService_PrepareLINENotification,
      callback);
};


/**
 * @param {!proto.api.PrepareLINENotificationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.PrepareLINENotificationResponse>}
 *     Promise that resolves to the response
 */
proto.api.NotificationServicePromiseClient.prototype.prepareLINENotification =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.NotificationService/PrepareLINENotification',
      request,
      metadata || {},
      methodDescriptor_NotificationService_PrepareLINENotification);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.CreateLINENotificationRequest,
 *   !proto.api.CreateLINENotificationResponse>}
 */
const methodDescriptor_NotificationService_CreateLINENotification = new grpc.web.MethodDescriptor(
  '/api.NotificationService/CreateLINENotification',
  grpc.web.MethodType.UNARY,
  proto.api.CreateLINENotificationRequest,
  proto.api.CreateLINENotificationResponse,
  /**
   * @param {!proto.api.CreateLINENotificationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.CreateLINENotificationResponse.deserializeBinary
);


/**
 * @param {!proto.api.CreateLINENotificationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.CreateLINENotificationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.CreateLINENotificationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.NotificationServiceClient.prototype.createLINENotification =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.NotificationService/CreateLINENotification',
      request,
      metadata || {},
      methodDescriptor_NotificationService_CreateLINENotification,
      callback);
};


/**
 * @param {!proto.api.CreateLINENotificationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.CreateLINENotificationResponse>}
 *     Promise that resolves to the response
 */
proto.api.NotificationServicePromiseClient.prototype.createLINENotification =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.NotificationService/CreateLINENotification',
      request,
      metadata || {},
      methodDescriptor_NotificationService_CreateLINENotification);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.ListLINENotificationRequest,
 *   !proto.api.ListLINENotificationResponse>}
 */
const methodDescriptor_NotificationService_ListLINENotification = new grpc.web.MethodDescriptor(
  '/api.NotificationService/ListLINENotification',
  grpc.web.MethodType.UNARY,
  proto.api.ListLINENotificationRequest,
  proto.api.ListLINENotificationResponse,
  /**
   * @param {!proto.api.ListLINENotificationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.ListLINENotificationResponse.deserializeBinary
);


/**
 * @param {!proto.api.ListLINENotificationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.ListLINENotificationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.ListLINENotificationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.NotificationServiceClient.prototype.listLINENotification =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.NotificationService/ListLINENotification',
      request,
      metadata || {},
      methodDescriptor_NotificationService_ListLINENotification,
      callback);
};


/**
 * @param {!proto.api.ListLINENotificationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.ListLINENotificationResponse>}
 *     Promise that resolves to the response
 */
proto.api.NotificationServicePromiseClient.prototype.listLINENotification =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.NotificationService/ListLINENotification',
      request,
      metadata || {},
      methodDescriptor_NotificationService_ListLINENotification);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.UpdateLINENotificationRequest,
 *   !proto.api.UpdateLINENotificationResponse>}
 */
const methodDescriptor_NotificationService_UpdateLINENotification = new grpc.web.MethodDescriptor(
  '/api.NotificationService/UpdateLINENotification',
  grpc.web.MethodType.UNARY,
  proto.api.UpdateLINENotificationRequest,
  proto.api.UpdateLINENotificationResponse,
  /**
   * @param {!proto.api.UpdateLINENotificationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.UpdateLINENotificationResponse.deserializeBinary
);


/**
 * @param {!proto.api.UpdateLINENotificationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.UpdateLINENotificationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.UpdateLINENotificationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.NotificationServiceClient.prototype.updateLINENotification =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.NotificationService/UpdateLINENotification',
      request,
      metadata || {},
      methodDescriptor_NotificationService_UpdateLINENotification,
      callback);
};


/**
 * @param {!proto.api.UpdateLINENotificationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.UpdateLINENotificationResponse>}
 *     Promise that resolves to the response
 */
proto.api.NotificationServicePromiseClient.prototype.updateLINENotification =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.NotificationService/UpdateLINENotification',
      request,
      metadata || {},
      methodDescriptor_NotificationService_UpdateLINENotification);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.TestLINENotificationRequest,
 *   !proto.api.TestLINENotificationResponse>}
 */
const methodDescriptor_NotificationService_TestLINENotification = new grpc.web.MethodDescriptor(
  '/api.NotificationService/TestLINENotification',
  grpc.web.MethodType.UNARY,
  proto.api.TestLINENotificationRequest,
  proto.api.TestLINENotificationResponse,
  /**
   * @param {!proto.api.TestLINENotificationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.TestLINENotificationResponse.deserializeBinary
);


/**
 * @param {!proto.api.TestLINENotificationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.TestLINENotificationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.TestLINENotificationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.NotificationServiceClient.prototype.testLINENotification =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.NotificationService/TestLINENotification',
      request,
      metadata || {},
      methodDescriptor_NotificationService_TestLINENotification,
      callback);
};


/**
 * @param {!proto.api.TestLINENotificationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.TestLINENotificationResponse>}
 *     Promise that resolves to the response
 */
proto.api.NotificationServicePromiseClient.prototype.testLINENotification =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.NotificationService/TestLINENotification',
      request,
      metadata || {},
      methodDescriptor_NotificationService_TestLINENotification);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.DeleteLINENotificationRequest,
 *   !proto.api.DeleteLINENotificationResponse>}
 */
const methodDescriptor_NotificationService_DeleteLINENotification = new grpc.web.MethodDescriptor(
  '/api.NotificationService/DeleteLINENotification',
  grpc.web.MethodType.UNARY,
  proto.api.DeleteLINENotificationRequest,
  proto.api.DeleteLINENotificationResponse,
  /**
   * @param {!proto.api.DeleteLINENotificationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.DeleteLINENotificationResponse.deserializeBinary
);


/**
 * @param {!proto.api.DeleteLINENotificationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.DeleteLINENotificationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.DeleteLINENotificationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.NotificationServiceClient.prototype.deleteLINENotification =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.NotificationService/DeleteLINENotification',
      request,
      metadata || {},
      methodDescriptor_NotificationService_DeleteLINENotification,
      callback);
};


/**
 * @param {!proto.api.DeleteLINENotificationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.DeleteLINENotificationResponse>}
 *     Promise that resolves to the response
 */
proto.api.NotificationServicePromiseClient.prototype.deleteLINENotification =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.NotificationService/DeleteLINENotification',
      request,
      metadata || {},
      methodDescriptor_NotificationService_DeleteLINENotification);
};


module.exports = proto.api;

