<template>
    <!-- <form v-if="!account" class="form-signin text-center mt-5">
        <img class="mb-4" src="/images/logo.svg" alt="" width="128" height="128">
        <button v-for="auth in auths" :key="auth.name" class="btn btn-lg btn-primary btn-block" type="button">{{auth.name}}</button>
        
    </form> -->
  <div id="container" class="flex-grow-1">
    <main class="form-signin w-100 m-auto text-center">
      <form>
        <img class="mb-4" src="/images/logo.svg" alt="" width="96" height="96">
        <p class="mt-5 mb-3 text-muted">正在登入...</p>
      </form>
    </main>
  </div>
</template>
<script>
import { useCookies } from "vue3-cookies"
import { AuthenticationServiceClient } from "../api/authentication_grpc_web_pb"
import { LoginRequest } from '../api/authentication_pb'
export default {
  props: ['error', 'state', 'code'],
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  data() {
    return {
    }
  },
  mounted: function () {
    this.auth = this.$route.params.auth
    const client = new AuthenticationServiceClient('')
    client.login(new LoginRequest().setSessionId(this.state).setCode(this.code), null, (err, res) => {
      if (err) {
        alert(`${err.code} ${err.message}`)
      } else {
        console.log('login success: ' + this.state)
        this.$user.session(this.state)
        this.$router.push(res.getRedirect())
      }
    })
  },
  methods: {
  },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#container {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 120px;
  background-color: #f5f5f5;
}

.form-signin {
  max-width: 330px;
  padding: 15px;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

</style>
