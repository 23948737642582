<template>
  <div class="modal modal-lg fade" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <div v-if="activity" class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="notificationSwitch"
              :checked="activity.getNotification()" @click="toggleNotification()">
            <label class="form-check-label fw-bold" for="notificationSwitch">推播通知</label>
          </div>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div v-for="notif in notifications" :key="notif.getId()" class="d-flex flex-row align-items-center mb-2">
            <img src="https://scdn.line-apps.com/n/line_notice/img/icon/favicon_160829.ico" width="24" height="24"/>
            <div class="flex-grow-1 fw-bold ms-2">
              {{ notif.getTarget() }} [{{ notif.getType() == 'USER' ? '使用者' : '群組' }}]
              <span v-if="notif.getStatus()" class="text-danger fw-bold ms-1">(錯誤: {{ notif.getStatus() }})</span>
              <span v-if="notif.error" class="text-danger fw-bold ms-1">(錯誤: {{ notif.error.message }})</span>
            </div>
            <div class="form-check form-switch form-check-reverse">
              <input class="form-check-input" type="checkbox" role="switch" :checked="notif.getEnable()" :id="notif.getId()" @click="toggleLINENotification(notif)">
              <label class="form-check-label" :for="notif.getId()">{{notif.getEnable() ? '已啟用' : '已停用'}}</label>
            </div>
            <button type="button" class="btn btn-primary btn-sm ms-2" @click="testLINENotification(notif)">測試</button>
            <button type="button" class="btn btn-danger btn-sm ms-1" @click="deleteLINENotification(notif)">刪除</button>
          </div>
          <div v-if="!notifications || !notifications.length">(還沒有設定任何通知)</div>
          <!-- <div class="d-flex align-items-center mb-2">
            <img src="https://scdn.line-apps.com/n/line_notice/img/icon/favicon_160829.ico" width="24" height="24"/>
            <div class="flex-grow-1"><input type="text" class="form-control form-control-sm mx-1" placeholder="LINE Notify 權杖" aria-label="LINE Notify 權杖" aria-describedby="button-addon2" v-model="token"></div>
            <button class="btn btn-success btn-sm ms-2" type="button" id="button-addon2" @click="createLINENotification()" >新增</button>
          </div> -->
        </div>
        <div class="modal-footer">
          <button class="btn btn-success" type="button" id="button-add-token" @click="startSession()" >新增</button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">關閉</button>
          <!-- <button type="button" class="btn btn-primary">套用設定</button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ActivityServiceClient } from '../api/activity_grpc_web_pb'
import { NotificationServiceClient } from '../api/notification_grpc_web_pb'
import { UpdateActivityRequest } from '../api/activity_pb'
import { CreateLINENotificationRequest, DeleteLINENotificationRequest, ListLINENotificationRequest, PrepareLINENotificationRequest, TestLINENotificationRequest, UpdateLINENotificationRequest } from '../api/notification_pb'
export default {
  props: ['activity'],
  emits: ['populated', 'notificationChanged'],
  data() {
    return {
      notifications: null,
      token: null,
    }
  },
  mounted: function () {
    if (this.activity) {
      this.populate()
    }
  },
  watch: {
    activity() {
      this.populate()
    },
  },
  methods: {
    populate: function () {
      this.notification = this.activity.getNotification()
      const client = new NotificationServiceClient('')
      client.listLINENotification(new ListLINENotificationRequest().setActivityId(this.activity.getId()), null, (err, res) => {
        if (err) {
          alert(`${err.code} ${err.message}`)
        } else {
          this.notifications = res.getNotificationsList()
          this.$emit('populated')
        }
      })
    },
    startSession: function () {
      const redirectUri = window.location.pathname + '#alerts'
      const client = new NotificationServiceClient('')
      client.prepareLINENotification(new PrepareLINENotificationRequest()
          .setActivityId(this.activity.getId())
          .setRedirectUri(redirectUri), null, (err, res) => {
        if (err) {
          alert(`${err.code} ${err.message}`)
        } else {
          this.requestToken(res.getClientId(), encodeURIComponent(res.getState()), encodeURIComponent(res.getRedirectUri()))
        }
      })
    },
    requestToken: function(client_id, state, redirect_uri) {
      window.location.href = 'https://notify-bot.line.me/oauth/authorize?' +
            'scope=notify&' +
            'state=' + state + '&' +
            'redirect_uri=' + redirect_uri + '&' +
            'response_type=code&' +
            'response_mode=form_post&' +
            'client_id=' + client_id
    },
    toggleNotification: function () {
      this.activity.setNotification(!this.activity.getNotification())
      const client = new ActivityServiceClient('')
      client.updateActivity(new UpdateActivityRequest().setActivity(this.activity), null, (err) => {
        if (err) {
          alert(`${err.code} ${err.message}`)
        } else {
          this.$emit('notificationChanged', this.activity.getNotification())
        }
      })
    },
    createLINENotification: function () {
      const client = new NotificationServiceClient('')
      client.createLINENotification(new CreateLINENotificationRequest()
          .setActivityId(this.activity.getId())
          .setToken(this.token), null, (err, res) => {
        if (err) {
          alert(`${err.code} ${err.message}`)
        } else {
          this.token = null
          this.notifications.push(res.getNotification())
        }
      })
    },
    toggleLINENotification: function (notif) {
      notif.setEnable(!notif.getEnable())
      const client = new NotificationServiceClient('')
      client.updateLINENotification(new UpdateLINENotificationRequest()
          .setActivityId(this.activity.getId())
          .setNotification(notif), null, (err) => {
        if (err) {
          alert(`${err.code} ${err.message}`)
        }
      })
    },
    testLINENotification: function (notif) {
      const client = new NotificationServiceClient('')
      client.testLINENotification(new TestLINENotificationRequest()
          .setActivityId(this.activity.getId())
          .setNotificationId(notif.getId()), null, (err) => {
        if (err) {
          notif.error = err
        }
      })
    },
    deleteLINENotification: function (notif) {
      const client = new NotificationServiceClient('')
      client.deleteLINENotification(new DeleteLINENotificationRequest()
          .setActivityId(this.activity.getId())
          .setNotificationId(notif.getId()), null, (err) => {
        if (err) {
          alert(`${err.code} ${err.message}`)
        } else {
          this.notifications = this.notifications.filter((n) => {
            return n != notif
          })
        }
      })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
