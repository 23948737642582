import { useCookies } from "vue3-cookies"
import { AuthenticationServiceClient } from "@/api/authentication_grpc_web_pb"
import { GetUserProfileRequest, LogoutRequest } from '@/api/authentication_pb'
export default {
  install: (app) => {
    app.config.globalProperties.$user = {
      session(session = null) {
        const { cookies } = useCookies()
        if (session) {
          cookies.set('session', session)
        } else {
          const session = cookies.get('session')
          // var stackTrace = Error().stack
          // console.log('session', session, stackTrace)
          return session
        }
      },
      profile(callback) {
        const { cookies } = useCookies()
        const session = this.session()
        if (!session) {
          callback(null, null)
          return
        }
        const client = new AuthenticationServiceClient('')
        client.getUserProfile(new GetUserProfileRequest().setSessionId(session), null, (err, res) => {
          if (err) {
            cookies.remove('session')
            callback(err)
            return
          }
          const user = { name: res.getName(), avatar: res.getAvatarUrl() }
          callback(null, user)
        })
      },
      logout() {
        const client = new AuthenticationServiceClient('')
        const session = this.session()
        if (!session) {
          return
        }
        client.logout(new LogoutRequest().setSessionId(session), null, () => {})
        const { cookies } = useCookies()
        cookies.remove('session')
      },
    }
  }
}
