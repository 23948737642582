<template>
  <div class="modal fade" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 v-if="id" class="modal-title">編輯航點: {{ name }}</h5>
          <h5 v-else class="modal-title">新增航點</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <label for="waypointName" class="form-label">名稱</label>
            <input type="text" class="form-control" id="waypointName" placeholder="航點名稱" v-model="name">
          </div>
          <div class="mb-3">
            <label for="waypointLat" class="form-label">緯度</label>
            <input type="number" class="form-control" id="waypointLat" placeholder="緯度" v-model="lat">
          </div>
          <div class="mb-3">
            <label for="waypointLng" class="form-label">經度</label>
            <input type="number" class="form-control" id="waypointLng" placeholder="經度" v-model="lng">
          </div>
          <!-- <div class="mb-3">
            <label for="waypointAlt" class="form-label">高程</label>
            <input type="number" class="form-control" id="waypointAlt" placeholder="高程" v-model="alt">
          </div> -->
          <div class="mb-3">
            <label for="waypointRadius" class="form-label">推播通知: {{ radius > 0 ? `進入或離開半徑 ${radius} 公尺範圍` : '關閉' }}</label>
            <input type="range" class="form-range" min="0" max="500" step="10" id="waypointRadius" v-model="radius">
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="cancel">取消</button>
          <button v-if="id" type="button" class="btn btn-danger" @click="remove">刪除</button>
          <button type="button" class="btn btn-primary" @click="createOrUpdate">確定</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WaypointServiceClient } from '@/api/waypoint_grpc_web_pb'
import { CreateWaypointRequest, DeleteWaypointRequest, UpdateWaypointRequest, Waypoint } from '../api/waypoint_pb'

export default {
  props: ['activityId', 'waypoint'],
  emits: ['created', 'updated', 'canceled', 'deleted'],
  data() {
    return {
      id: null,
      name: null,
      lat: null,
      lng: null,
      alt: null,
      radius: 0,
    }
  },
  mounted: function () {
  },
  watch: {
    waypoint(wpt) {
      this.id = wpt.id
      this.name = wpt.name
      this.lat = wpt.lat
      this.lng = wpt.lng
      this.alt = wpt.alt
      this.radius = wpt.radius
    }
  },
  methods: {
    createOrUpdate: function() {
      if (this.id) {
        this.update()
      } else {
        this.create()
      }
    },
    create: function() {
      const waypoint = new Waypoint()
          .setName(this.name)
          .setLatitude(this.lat)
          .setLongitude(this.lng)
          .setGeofenceRadius(this.radius)
      const client = new WaypointServiceClient('')
      client.createWaypoint(new CreateWaypointRequest()
          .setActivityId(this.activityId)
          .setWaypoint(waypoint), null, (err, res) => {
        if (err) {
          alert(`${err.code} ${err.message}`)
        } else {
          this.$emit('created', {
            id: res.getWaypointId(),
            name: this.name,
            lat: this.lat,
            lng: this.lng,
            radius: Number(this.radius),
          })
        }
      })
    },
    update: function () {
      const waypoint = new Waypoint()
          .setId(this.id)
          .setName(this.name)
          .setLatitude(this.lat)
          .setLongitude(this.lng)
          .setGeofenceRadius(this.radius)
      const client = new WaypointServiceClient('')
      client.updateWaypoint(new UpdateWaypointRequest()
          .setActivityId(this.activityId)
          .setWaypoint(waypoint), null, (err) => {
        if (err) {
          alert(`${err.code} ${err.message}`)
        } else {
          this.$emit('updated', {
            id: this.id,
            name: this.name,
            lat: this.lat,
            lng: this.lng,
            radius: Number(this.radius),
          })
        }
      })
    },
    cancel: function () {
      this.$emit('canceled', {
        id: this.id,
      })
    },
    remove: function () {
      const client = new WaypointServiceClient('')
      client.deleteWaypoint(new DeleteWaypointRequest()
          .setActivityId(this.activityId)
          .setWaypointId(this.id), null, (err) => {
        if (err) {
          alert(`${err.code} ${err.message}`)
        } else {
          this.$emit('deleted', {
            id: this.id
          })
        }
      })
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
