<template>
  <nav class="navbar navbar-expand-lg bg-light">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#sidebar"
        aria-controls="sidebar" aria-expanded="false" aria-label="Toggle side bar">
        <span class="navbar-toggler-icon"></span>
      </button>
      <span v-if="brand" class="navbar-brand d-block d-sm-none">{{ brand }}</span>
      <img class="d-none d-sm-block me-1" src="/images/logo.svg" alt="Logo" width="24" height="24"/>
      <a class="navbar-brand d-none d-sm-block" href="/">
        戶外安全整合平台
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <!-- <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#">我的圖台</a>
          </li> -->
          <!-- <li class="nav-item">
            <a class="nav-link" href="#">Link</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Dropdown
            </a>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Another action</a></li>
              <li>
                <hr class="dropdown-divider">
              </li>
              <li><a class="dropdown-item" href="#">Something else here</a></li>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link disabled">Disabled</a>
          </li> -->
        </ul>
        <!-- <form class="d-flex" role="search">
          <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
          <button class="btn btn-outline-success" type="submit">Search</button>
        </form> -->
        <div v-if="user" class="dropdown">
          <button class="dropdown-toggle border-0 bg-transparent" type="button" id="menu1" data-bs-toggle="dropdown">
            <img class="avatar" :src="user.avatar">
            <span class="caret"></span>
          </button>
          <!-- <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false">>
            <img class="avatar" :src="user.avatar" />
          </a> -->
          <div class="dropdown-menu dropdown-menu-end">
            <button type="button" class="dropdown-item" @click="logout()">登出 {{user.name}}…</button>
            <!-- <div class="dropdown-divider"></div>
            <button type="button" class="dropdown-item" data-toggle="modal" data-target="#aboutModal">About&#8230;</button> -->
          </div>
        </div>
        <button v-else-if="adminConsole" class="btn btn-primary" type="button" @click="startSession()">登入</button>
      </div>
    </div>
  </nav>
</template>
<script>
import { AuthenticationServiceClient } from "@/api/authentication_grpc_web_pb"
import { StartSessionRequest } from '@/api/authentication_pb'
import { isAdminConsole } from '../util'
export default {
  props: ['brand'],
  data() {
    return {
      user: null,
    }
  },
  mounted: function () {
  },
  watch: {
    $route() {
      this.getUser()
    },
  },
  computed: {
    adminConsole() {
      return isAdminConsole()
    },
  },
  methods: {
    getUser: function() {
      this.$user.profile((err, user) => {
        if (!err) this.user = user
      })
    },
    startSession: function() {
      const callbackUrl = window.location.origin + '/authcb'
      const redirectUri = window.location.pathname
      const req = new StartSessionRequest()
          .setCallbackUrl(callbackUrl)
          .setRedirectUri(redirectUri)

      const client = new AuthenticationServiceClient('')
      client.startSession(req, null, (err, res) => {
        if (err) {
          alert(`${err.code} ${err.message}`)
        } else {
          this.signin(res.getClientId(), encodeURIComponent(res.getSessionId()), encodeURIComponent(callbackUrl))
        }
      })
    },
    signin: function(client_id, state, redirect_uri) {
      window.location.href = 'https://accounts.google.com/o/oauth2/v2/auth?' +
            'scope=openid%20profile%20email&' +
            'state=' + state + '&' +
            'redirect_uri=' + redirect_uri + '&' +
            'response_type=code&' +
            'client_id=' + client_id
    },
    logout: function() {
      this.$user.logout()
      this.user = null
    },
  },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.avatar {
  border-radius: 50%;
  width: 32px;
  height: 32px;
}

.avatar-loading {
  width: 32px;
  height: 32px;
}

.spinner-border {
  width: 32px;
  height: 32px;
}
</style>
