<template>
  <div class="container text-center pt-5">
    <div class="row">
      <div class="col-lg-4">
        <svg class="bd-placeholder-img rounded-circle" width="140" height="140" xmlns="http://www.w3.org/2000/svg"
          role="img" aria-label="Placeholder: 140x140" preserveAspectRatio="xMidYMid slice" focusable="false">
          <title>Placeholder</title>
          <rect width="100%" height="100%" fill="#777" /><text x="50%" y="50%" fill="#777" dy=".3em">140x140</text>
        </svg>

        <h2 class="fw-normal pt-2">以公尺為單位的等高線地圖</h2>
        <p>總是不習慣 Explore 或 MapShare 上的英呎單位等高線跟標高嗎？沒問題，我們採用 Mapbox 世界級圖資，還支援3D模式讓你的航跡「站起來」！</p>
      </div><!-- /.col-lg-4 -->
      <div class="col-lg-4">
        <svg class="bd-placeholder-img rounded-circle" width="140" height="140" xmlns="http://www.w3.org/2000/svg"
          role="img" aria-label="Placeholder: 140x140" preserveAspectRatio="xMidYMid slice" focusable="false">
          <title>Placeholder</title>
          <rect width="100%" height="100%" fill="#777" /><text x="50%" y="50%" fill="#777" dy=".3em">140x140</text>
        </svg>

        <h2 class="fw-normal pt-2">主動式通知動態</h2>
        <p>留守時總是要不時打開 Explore 或 MapShare 來查看動態嗎？沒問題，我們會主動發出各種事件通知，包括開啟追蹤、關閉追蹤、抵達地點以及離開地點等等。</p>
      </div><!-- /.col-lg-4 -->
      <div class="col-lg-4">
        <svg class="bd-placeholder-img rounded-circle" width="140" height="140" xmlns="http://www.w3.org/2000/svg"
          role="img" aria-label="Placeholder: 140x140" preserveAspectRatio="xMidYMid slice" focusable="false">
          <title>Placeholder</title>
          <rect width="100%" height="100%" fill="#777" /><text x="50%" y="50%" fill="#777" dy=".3em">140x140</text>
        </svg>

        <h2 class="fw-normal pt-2">多種推播方式</h2>
        <p>除了基本的電子郵件通知之外，更支援 LINE 通知及互動式動態查詢，不必再苦苦守在圖台前！</p>
      </div><!-- /.col-lg-4 -->
    </div><!-- /.row -->
    <div class="row">
      <div class="col-lg-4">
        <svg class="bd-placeholder-img rounded-circle" width="140" height="140" xmlns="http://www.w3.org/2000/svg"
          role="img" aria-label="Placeholder: 140x140" preserveAspectRatio="xMidYMid slice" focusable="false">
          <title>Placeholder</title>
          <rect width="100%" height="100%" fill="#777" /><text x="50%" y="50%" fill="#777" dy=".3em">140x140</text>
        </svg>

        <h2 class="fw-normal pt-2">跨帳號的 InReach 整合</h2>
        <p>出勤時有的裝置是個人版又有的是專業版，整合不起來嗎？沒問題，不管什麼版的 InReach 裝置都可以整合在同一個圖台，適合搜救或賽事等大型活動。</p>
      </div><!-- /.col-lg-4 -->
      <div class="col-lg-4">
        <svg class="bd-placeholder-img rounded-circle" width="140" height="140" xmlns="http://www.w3.org/2000/svg"
          role="img" aria-label="Placeholder: 140x140" preserveAspectRatio="xMidYMid slice" focusable="false">
          <title>Placeholder</title>
          <rect width="100%" height="100%" fill="#777" /><text x="50%" y="50%" fill="#777" dy=".3em">140x140</text>
        </svg>

        <h2 class="fw-normal pt-2">靈活的行程管理保護隱私</h2>
        <p>想用方便管理多台設備的專業版圖台，但又不想要在 MapShare 上同時出現所有裝置嗎？沒問題，可以日期及時間建立活動並指派不同裝置，適合設備出租或出借管理。</p>
      </div><!-- /.col-lg-4 -->
      <div class="col-lg-4">
        <svg class="bd-placeholder-img rounded-circle" width="140" height="140" xmlns="http://www.w3.org/2000/svg"
          role="img" aria-label="Placeholder: 140x140" preserveAspectRatio="xMidYMid slice" focusable="false">
          <title>Placeholder</title>
          <rect width="100%" height="100%" fill="#777" /><text x="50%" y="50%" fill="#777" dy=".3em">140x140</text>
        </svg>

        <h2 class="fw-normal pt-2">彈性的整合方式</h2>
        <p>個人版圖台支援 KML Feed 整合方式，專業版圖台可同時支援 KML Feed 及 IPC Outbound 兩種整合方式強化即時性及可靠性。</p>
      </div><!-- /.col-lg-4 -->
    </div><!-- /.row -->
    <div id="footer" class="row pt-4 mt-3">
      <!-- <p class="float-right">
                <a href="#">Back to top</a>
            </p> -->
      <p>
        © {{ new Date().getFullYear() }} <a href="https://outdoorsafetylab.org/">Outdoor Safety Lab</a>
        <span v-if="version"> &middot; {{ version }}</span>
        <span v-if="time"> &middot; ({{ time.toLocaleString() }})</span>
      </p>
    </div>
  </div>
</template>
<script>
import { SystemServiceClient } from '@/api/system_grpc_web_pb';
import { GetVersionRequest } from '@/api/system_pb';

export default {
  data() {
    return {
      version: null,
      time: null,
    }
  },
  mounted: function () {
    const client = new SystemServiceClient('')
    client.getVersion(new GetVersionRequest(), null, (err, res) => {
      if (!err) {
        if (res.getGitTag()) {
          this.version = res.getGitTag()
        } else if (res.getGitCommit()){
          this.version = res.getGitCommit()
        }
        if (res.getTimeInMillis()) {
          this.time = new Date(res.getTimeInMillis())
        }
      }
    })
  },
  methods: {
  },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#footer {
  border-top: 1px solid lightgray;
  color: gray;
}
</style>
