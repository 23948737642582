import mapboxgl from 'mapbox-gl'
import { Markable } from './Markable'
import { Track } from './Track'
import { durationString } from '../util'

export class Tracker extends Markable {
  constructor(map, id, preferredName, displayName, colors, visible) {
    super(map, id, visible)
    this.colors = colors
    this.messages = []
    this.tracks = []
    this.lastLocation = null
    this.lastOnline = null
    this.preferredName = preferredName
    this.displayName = displayName
    this._setName(this.getName())
  }
  addHeartBeat(beat) {
    const {time} = beat
    if (!this.lastOnline || this.lastOnline.getTime() < time.getTime()) {
      this.lastOnline = time
    }
  }
  start(pos) {
    if (this.tracks.length > 0) {
      const last = this._getLastTrack()
      if (!last.stop) {
        this.stop(this.lastLocation)
      }
    }
    const track = new Track(this.map, `${this.id}_track${this.tracks.length}`, this.colors, this.visible)
    track.startTrack(pos)
    this.tracks.push(track)
    this._setLastLocation(pos)
  }
  stop(pos) {
    if (this.tracks.length <= 0) {
      console.log('Stopping without any track')
      return
    }
    this._getLastTrack().stopTrack(pos)
    this._setLastLocation(pos)
  }
  addPosition(pos) {
    const {time} = pos
    this.addHeartBeat(pos)
    if (this.tracks.length <= 0 || (time.getTime()-this.lastLocation.time.getTime()) > 3*86400*1000) {
      this.start(pos)
      return
    }
    const latest = this._getLastTrack().addPosition(pos)
    this._setLastLocation(latest)
  }
  addMessage(data) {
    const {time, lat, lng, alt, msg, delay} = data
    if (lat && lng && alt) {
      const popup = new mapboxgl.Popup({offset: 10}).setHTML(`<div class="popup">時間: ${time.toLocaleString()} (延遲${ durationString(delay) })<br/>位置: ${lat.toFixed(6)},${lng.toFixed(6)}<br/>高度: ${alt}<br/>訊息: ${msg || '(無內容)'}</div>`)
      const el = document.createElement('div')
      el.className = 'message'
      const marker = new mapboxgl.Marker(el)
        .setLngLat([lng, lat])
        .setPopup(popup)
      data.marker = this.addMarker(marker, 9, this.getName(), 15, this.textColor, this.haloColor)
    }
    this.messages.push(data)
  }
  setDisplayName(name) {
    this.display_name = name
    this._setName(this.getName())
  }
  getName() {
    return this.preferredName || this.displayName || this.id.replace(/^InReach:/, '')
  }
  getBounds() {
    var bounds = null
    this.tracks.forEach((track) => {
      if (bounds) {
        bounds = bounds.extend(track.bounds)
      } else {
        bounds = new mapboxgl.LngLatBounds(track.bounds.getSouthWest(), track.bounds.getNorthEast())
      }
    })
    return bounds
  }
  setVisible(visible) {
    super.setVisible(visible)
    this.tracks.forEach((track) => {      
      track.setVisible(visible)
    })
  }
  _setName(name) {
    if (this.name == name) return
    this.name = name
    if (this.marker) this.marker.setLabel(name)
    this.messages.forEach((msg) => {
      const {marker} = msg
      if (marker) marker.setLabel(name)
    })
  }
  _getLastTrack() {
    if (!this.tracks.length) return null
    return this.tracks[this.tracks.length-1]
  }
  _setLastLocation(pos) {
    const {time, lat, lng, alt} = pos
    const lngLat = [lng, lat]
    const popup = new mapboxgl.Popup({offset: 10}).setHTML(`<div class="popup">時間: ${time.toLocaleString()}<br/>位置: ${lat.toFixed(6)},${lng.toFixed(6)}<br/>高度: ${alt}</div>`)
    if (!this.marker) {
      const el = document.createElement('div')
      el.className = 'last-location'
      const marker = new mapboxgl.Marker(el, {anchor: 'bottom'})
        .setLngLat(lngLat)
        .setPopup(popup)
      this.marker = this.addMarker(marker, 0, this.getName(), 15, this.colors)
    } else {
      this.marker
        .setLngLat(lngLat)
        .setPopup(popup)
    }
    this.lastLocation = pos
  }
}
