
export class Mapble {
  constructor(map, id, visible) {
    this.map = map
    this.id = id
    this.visible = visible
    this.layers = []
    this.bounds = null
    this.following = false
    map.on('style.load', () => {
      console.log('reloading all layers')
      this.layers.forEach((obj) => {
        const {id, source, layer} = obj
        layer.layout.visibility = this._visibility()
        this.map.addSource(id, source)
        this.map.addLayer(layer)
      })
    })
  }
  htmlSafeID() {
    return this.id.replace(':','')
  }
  setVisible(visible) {
    this.visible = visible
    this.invalidate()
  }
  isVisible() {
    return this.visible
  }
  addLayer(id, type, data, layer) {
    layer.id = id
    layer.source = id
    const visibility = this._visibility()
    if (layer.layout) {
      layer.layout.visibility = visibility
    } else {
      layer.layout = {
        visibility,
      }
    }
    const source = {type, data}
    this.layers.push({id, data, source, layer})
    this.map.addSource(id, source)
    this.map.addLayer(layer)
  }
  getLastLayer() {
    if (this.layers.length <= 0) {
      return null
    }
    return this.layers[this.layers.length-1]
  }
  invalidate() {
    this.layers.forEach((obj) => {
      const {id, data} = obj
      this.map.getSource(id).setData(data)
      this.map.setLayoutProperty(id, 'visibility', this._visibility())
    })
  }
  _visibility() {
    return this.isVisible() ? 'visible' : 'none'
  }
}
