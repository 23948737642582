<template>
  <div class="flex-grow-1 d-flex flex-row overflow-hidden">
    <AlertModal id="alertModal" :activity="remoteActivity" @populated="onNotificationPopulated" @notificationChanged="onNotificationChanged"/>
    <WaypointModal v-if="activity" id="waypointModal" :activityId="activity.id" :waypoint="waypoint" @created="onWaypointCreated" @updated="onWaypointUpdated" @canceled="onWaypointCanceled" @deleted="onWaypointDeleted"/>
    <div class="bg-white offcanvas-lg offcanvas-start overflow-auto mh-100 border-top" id="sidebar"
      style="width: 300px;">
      <ul class="list-group list-group-flush border-top border-bottom">
        <li v-if="activity" class="list-group-item d-flex flex-column p-0">
          <div class="d-flex flex-row align-items-center pe-1">
            <button
              class="btn-toggle d-inline-flex flex-grow-1 align-items-center border-0 py-2 me-1 text-start text-wrap fw-bold"
              data-bs-toggle="collapse" data-bs-target="#activityCollapse" aria-expanded="false">
              {{ activity.name }}
            </button>
            <button class="material-icons md-dark md-24 me-1 p-0 border-0 bg-transparent" data-bs-toggle="modal" data-bs-target="#alertModal" :disabled="!adminConsole">
              {{ activity.notification ? 'notifications' : 'notifications_off' }}
            </button>
            <button class="material-icons md-dark md-24 me-1 p-0 border-0 bg-transparent" @click="fitBounds(activity)">
              {{ bounding == activity ? 'fullscreen' : 'fullscreen_exit'}}
            </button>
          </div>
          <div class="collapse ms-4" id="activityCollapse">
            <div class="d-flex flex-row pe-1 pb-1">
              <div class="flex-grow-1 align-items-center lh-sm">
                <div><small>開始時間: <span v-if="activity.startTime">{{ activity.startTime.toLocaleString() }}</span><span v-else>(未設定)</span></small></div>
                <div><small>結束時間: <span v-if="activity.stopTime">{{ activity.stopTime.toLocaleString() }}</span><span v-else>(未設定)</span></small></div>
              </div>
              <button class="material-icons md-dark md-24 me-1 p-0 border-0 bg-transparent" disabled>
                calendar_month
              </button>
            </div>
            <ul class="list-group list-group-flush border-top">
              <li v-for="(wpt) in activity.waypoints" :key="`${wpt.name}`"
                class="list-group-item ps-0 pe-1 py-1">
                <div class="d-flex flex-row align-items-center">
                  <img src="https://maps.google.com/mapfiles/kml/paddle/red-circle.png" width="24" height="24" @click="flyTo(wpt)"/>
                  <button class="text-start bg-transparent border-0 flex-grow-1 lh-1" @click="flyTo(wpt)">
                    <small>{{ wpt.name }}</small>
                    <small>
                      <small v-if="wpt.radius"> (推播: <a href="https://zh.wikipedia.org/zh-tw/%E5%9C%B0%E7%90%86%E5%9B%B4%E6%A0%8F" target="_blank">半徑{{ wpt.radius }}公尺</a>)</small>
                      <small><br />{{ wpt.lat.toFixed(6) }},{{ wpt.lng.toFixed(6) }} </small>
                      <small v-if="wpt.alt"> h{{ wpt.alt.toFixed(0) }}m</small>
                    </small>
                  </button>
                  <button class="material-icons md-dark md-24 me-1 p-0 border-0 bg-transparent" @click="editWaypoint(wpt)" :disabled="!adminConsole">
                    edit
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </li>
        <li v-for="(tracker) in trackers" :key="tracker.id" class="list-group-item d-flex flex-column p-0">
          <div class="d-flex flex-row align-items-center pe-1">
            <button
              class="btn-toggle d-inline-flex flex-grow-1 align-items-center border-0 py-2 me-1 text-start text-wrap"
              data-bs-toggle="collapse" :data-bs-target="`#${tracker.htmlSafeID()}`"
              :aria-expanded="trackers.length == 1 ? 'true' : 'false'">
              <span data-bs-toggle="tooltip" data-bs-placement="bottom"
                :title="tracker.lastOnline ? '最後上線: ' + tracker.lastOnline.toLocaleString() : null">{{
                  tracker.getName()
                }}</span>
              <span class="badge rounded-pill ms-1"
                :style="{ 'background-color': tracker.colors.line, 'color': tracker.colors.badgeText }">{{
                  tracker.tracks.length
                }}</span>
            </button>
            <button v-if="tracker.visible && tracker.lastLocation"
              class="material-icons md-dark md-24 me-1 p-0 border-0 bg-transparent" @click="toggleFollow(tracker)">{{
                tracker.following ? 'my_location' : 'location_searching'
              }}</button>
            <button v-if="tracker.visible && tracker.tracks.length > 0 && tracker.tracks[0].coordinates.length > 1"
              class="material-icons md-dark md-24 me-1 p-0 border-0 bg-transparent" @click="fitBounds(tracker)">{{
              bounding== tracker ? 'fullscreen' : 'fullscreen_exit'}}</button>
            <button class="material-icons md-dark md-24 me-1 p-0 border-0 bg-transparent"
              @click="tracker.setVisible(!tracker.visible)">{{
                tracker.visible ? 'visibility' :
                  'visibility_off'
              }}</button>
            <button v-if="tracker.messages.length > 0"
              class="material-icons md-dark md-24 me-1 p-0 border-0 bg-transparent" data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"
              @click="setMessages(tracker.getName(), tracker.messages)">chat</button>
          </div>
          <div class="collapse" :class="{ show: trackers.length == 1 }" :id="`${tracker.htmlSafeID()}`">
            <ul class="btn-toggle-nav list-group list-group-flush border-top">
              <li v-if="!tracker.tracks.length" class="list-group-item pe-1 py-1"><small>還沒有任何紀錄...</small></li>
              <li v-for="(track, index) in tracker.tracks" :key="`${tracker.id}-${index}`"
                class="list-group-item ps-2 pe-1 py-1">
                <div class="d-flex justify-content-between align-items-center p-0">
                  <button class="btn-toggle d-inline-flex ms-0 py-1 text-start bg-transparent border-0 flex-grow-1"
                    data-bs-toggle="collapse" aria-expanded="false" :data-bs-target="`#${track.htmlSafeID()}`">
                    <small v-if="track.start.time == track.latest.time">時間: {{
                      track.start.time.toLocaleString()
                    }}</small>
                    <small v-else>
                      <span>開始: {{ track.start.time.toLocaleString() }}</span>
                      <span v-if="track.stop"><br />結束: {{ track.stop.time.toLocaleString() }}</span>
                      <span v-else><br />最後定位: {{ track.latest.time.toLocaleString() }}</span>
                    </small>
                  </button>
                  <button v-if="track.visible" class="material-icons md-dark md-24 me-1 p-0 border-0 bg-transparent"
                    @click="fitBounds(track)">{{ bounding== track ? 'fullscreen' : 'fullscreen_exit'}}</button>
                  <button v-if="track.coordinates.length > 1"
                    class="material-icons md-dark md-24 me-1 p-0 border-0 bg-transparent"
                    @click="track.setVisible(!track.visible)">{{
                      track.visible ? 'visibility' : 'visibility_off'
                    }}</button>
                  <!-- <span class="badge bg-primary rounded-pill">14</span> -->
                </div>
                <div class="collapse" :id="`${track.htmlSafeID()}`">
                  <ul class="list-group list-group-flush border-top">
                    <li v-for="(pos) in track.positions" :key="`${track.id}_${pos.time.getTime()}`"
                      class="list-group-item lh-1 ps-4 pe-1 py-1">
                      <button class="text-start bg-transparent border-0" @click="flyTo(pos)">
                        <small>
                          <small>{{ pos.time.toLocaleString() }}</small>
                          <small v-if="pos.delay"> (延遲{{ durationString(pos.delay) }})</small>
                          <small><br />({{ pos.lat.toFixed(6) }},{{ pos.lng.toFixed(6) }}) </small>
                          <small v-if="pos.alt"> h{{ pos.alt.toFixed(0) }}m</small>
                        </small>
                      </button>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasRightLabel">{{ messagesFrom }}傳送的訊息</h5>
        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body p-2">
        <div v-for="msg in messages" :key="msg.time.getTime()" class="card mb-1">
          <div class="card-header px-2 py-1 d-flex flex-row align-items-center">
            <small>{{ msg.time.toLocaleString() }}</small>
            <small v-if="msg.delay">&nbsp;(延遲{{ durationString(msg.delay) }})</small>
            <button v-if="msg.lat && msg.lng" class="material-icons md-dark md-24 ms-1 p-0 border-0 bg-transparent"
              @click="flyTo({ lng: msg.lng, lat: msg.lat })">my_location</button>
          </div>
          <div class="card-body px-2 py-1">
            <small>{{ msg.msg || '(無內容)' }}</small>
          </div>
        </div>
      </div>
    </div>
    <div id="map_container" class="flex-grow-1">
      <div id="menu" class="d-flex justify-content-start">
        <div>
          <label class="visually-hidden" for="inlineFormSelectStyle">底圖</label>
          <select class="form-select form-select-sm border-0 bg-transparent" id="inlineFormSelectStyle" v-model="style">
            <option v-for="style in styles" :key="style.id" :value="style.id">{{ style.name }}</option>
          </select>
        </div>
        <div class="form-control-sm">
          <input id="terrain" type="checkbox" v-model="terrain" class="form-check-input me-1">
          <label for="terrain" class="form-check-label me-1">3D地型</label>
        </div>
        <div class="form-control-sm">
          <input id="fullscreen" type="checkbox" v-model="fullscreen" class="form-check-input me-1">
          <label for="fullscreen" class="form-check-label">全螢幕</label>
        </div>
        <!-- <span v-if="location">({{location.lat.toFixed(6)}},{{location.lng.toFixed(6)}})</span> -->
      </div>
      <div id="map">
      </div>
    </div>
  </div>
</template>
<script>
import AlertModal from './AlertModal.vue'
import WaypointModal from './WaypointModal.vue'
import mapboxgl from 'mapbox-gl'
import MapboxLanguage from "@mapbox/mapbox-gl-language"
import { ActivityServiceClient } from "../api/activity_grpc_web_pb"
import { TrackerServiceClient } from "../api/tracker_grpc_web_pb"
import { StreamEventRequest, EventType, BatteryStatus } from '../api/tracker_pb'
import { Activity } from '../model/Activity'
import { GetSharedActivityRequest } from '../api/activity_pb'
import { WaypointServiceClient } from '@/api/waypoint_grpc_web_pb'
import { ListWaypointRequest } from '@/api/waypoint_pb'
import * as bootstrap from 'bootstrap'
import { durationString, isAdminConsole } from '../util'

const ViewModes = {
  FIT_BOUNDS: 0,
  FOLLOWING: 1,
}

const preferedZoom = 14

export default {
  props: ['center', 'zoom', 'alerts'],
  emits: ['navBrand'],
  components: {
    AlertModal,
    WaypointModal,
  },
  data() {
    return {
      // See a list of Mapbox-hosted public styles at https://docs.mapbox.com/api/maps/styles/#mapbox-styles
      styles: [
        { id: 'outdoors-v12', name: '戶外地圖' },
        { id: 'satellite-v9', name: '衛星地圖' },
      ],
      style: 'outdoors-v12',
      terrain: false,
      fullscreen: false,
      first: true,
      viewMode: null,
      bounding: null,
      location: null,
      remoteActivity: null,
      activity: null,
      trackers: [],
      messagesFrom: null,
      messages: [],
      waypoint: null,
    }
  },
  mounted: function () {
    window.createWaypoint = (wpt) => {
      this.createWaypoint(wpt)
    }
    window.editWaypoint = (id) => {
      this.activity.waypoints.forEach((wpt) => {
        if (wpt.id == id) {
          this.editWaypoint(wpt)
        }
      })
    }
    if (this.map) return
    this.shareCode = this.$route.params.shareCode
    mapboxgl.accessToken = 'pk.eyJ1Ijoib3V0ZG9vcnNhZmV0eWxhYiIsImEiOiJjazk5cmo2NnUwNThiM2VvaTBhcWdudzlyIn0.MKDLEWTJe5UXmahCrn0rOA';
    this.map = new mapboxgl.Map({
      container: 'map',
      bounds: [[120.106188593, 21.9705713974], [121.951243931, 25.2954588893]],
      style: `mapbox://styles/mapbox/${this.style}?optimize=true`,
    })
    this.map.loadImage('/images/arrow.png', (error, image) => {
      if (error) throw error;
      this.map.addImage('arrow', image)
    })
    this.map.addControl(new mapboxgl.NavigationControl())
    this.map.addControl(new mapboxgl.ScaleControl({ position: 'bottom-right' }))
    this.map.addControl(new MapboxLanguage({defaultLanguage: "zh-Hant"}))
    this.map.on('style.load', () => {
      this.map.addSource('mapbox-dem', {
        'type': 'raster-dem',
        'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
        'tileSize': 512,
        'maxzoom': 14
      })
      this.setTerrain()
    })
    this.map.on('mousemove', (e) => {
      const lngLat = e.lngLat
      this.location = { lng: lngLat.lng, lat: lngLat.lat }
    })
    this.map.on('dragend', () => {
      this.disableViewMode()
    })
    this.map.on('wheel', () => {
      this.disableViewMode()
    })
    this.map.on('load', () => {
      // this.map.addSource('happyman', {
      //   'type': 'raster',
      //   'tiles': [
      //     'http://wmts.nlsc.gov.tw/wmts/PHOTO_MIX/default/GoogleMapsCompatible/{z}/{y}/{x}'
      //     // 'http://gis.sinica.edu.tw/tileserver/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=TM25K_2001&STYLE=_null&TILEMATRIXSET=GoogleMapsCompatible&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&FORMAT=image/jpeg'
      //     // 'http://tile.happyman.idv.tw/map/moi_osm/{z}/{x}/{y}.png'
      //     // 'http://rudy.tile.basecamp.tw/{z}/{x}/{y}.png'
      //   ],
      //   'tileSize': 256
      // })
      // this.map.addLayer({
      //   'id': 'happyman', // Layer ID
      //   'type': 'raster',
      //   'source': 'happyman', // ID of the tile source created above
      //   }
      // )
      const el = document.createElement('div')
      el.className = 'crosshair'
      const center = this.map.getCenter()
      const crosshair = new mapboxgl.Marker(el, {anchor: 'center'})
          .setLngLat(center)
          .addTo(this.map)
      this.map.on('move', () => {
        const center = this.map.getCenter()
        const {lng, lat} = center
        const add = this.adminConsole ? `<div style="text-align: center; padding-top: 10px;"><button type="button" onclick="createWaypoint({lat: ${lat}, lng: ${lng}, radius: 0})">新增航點</button></div>` : ''
        const popup = new mapboxgl.Popup({offset: 10})
            .setHTML(`<div class="popup">${lat.toFixed(6)},${lng.toFixed(6)}${add}</div>`)
        crosshair.setLngLat(center).setPopup(popup)
      })
      this.getActivity()
    })
  },
  watch: {
    style(style) {
      console.log(`mapbox://styles/mapbox/${style}`)
      this.map.setStyle(`mapbox://styles/mapbox/${style}?optimize=true`)
    },
    terrain() {
      this.setTerrain()
    },
    fullscreen(fullscreen) {
      console.log(fullscreen)
      fullscreen ? this.enterFullscreen() : this.exitFullscreen()
    },
    activity(activity) {
      const client = new WaypointServiceClient('')
      client.listWaypoint(new ListWaypointRequest().setActivityId(activity.id), null, (err, res) => {
        if (err) {
          alert(`${err.code} ${err.message}`)
        } else {
          res.getWaypointsList().forEach((wpt) => {
            this.activity.addWaypoint({
              id: wpt.getId(),
              name: wpt.getName(),
              lat: wpt.getLatitude(),
              lng: wpt.getLongitude(),
              radius: wpt.getGeofenceRadius(),
            }, this.adminConsole ? (wpt) => {
              const [lng, lat] = wpt.marker.getLngLat()
              this.waypoint = {
                id: wpt.id,
                name: wpt.name,
                lat,
                lng,
                alt: wpt.alt,
                radius: wpt.radius,
              }
              this.waypointModal.show()
            } : null)
          })
          this.getEvents()
        }
      })
    },
  },
  computed: {
    waypointModal() {
      const el = document.getElementById('waypointModal')
      return new bootstrap.Modal(el, {})
    },
    adminConsole() {
      return isAdminConsole()
    },
  },
  methods: {
    setTerrain: function () {
      if (this.terrain) {
        // add the DEM source as a terrain layer with exaggerated height
        this.map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1 });
      } else {
        this.map.setTerrain()
      }
    },
    getActivity: function () {
      const client = new ActivityServiceClient('')
      client.getSharedActivity(new GetSharedActivityRequest().setShareCode(this.shareCode), null, (err, res) => {
        if (err) {
          alert(`${err.code} ${err.message}`)
        } else {
          this.remoteActivity = res.getActivity()
          const activity = res.getActivity()
          document.title = activity.getName()
          this.$emit('navBrand', activity.getName())
          this.activity = new Activity(this.map, activity.getId(), activity.getName(), false)
          if (activity.getStartTimeInMillis()) {
            this.activity.startTime = new Date(activity.getStartTimeInMillis())
          }
          if (activity.getStopTimeInMillis()) {
            this.activity.stopTime = new Date(activity.getStopTimeInMillis())
          }
          this.activity.notification = activity.getNotification()
          this.activity.on('newtracker', (tracker) => {
            this.trackers.push(tracker)
          })
          res.getTrackersList().forEach((tracker) => {
            this.activity.addTracker({
              id: tracker.getId(),
              preferredName: tracker.getPreferredName(),
              displayName: tracker.getDisplayName(),
            })
          })
        }
      })
    },
    getEvents: function () {
      var since = 0
      if (this.lastSince) {
        since = this.lastSince
      }
      console.log(`getting events since: ${since}`)
      const client = new TrackerServiceClient('')
      const events = client.streamEvent(new StreamEventRequest()
        .setActivityId(this.activity.id)
        .setSinceEpochInMillis(since))
      events.on('data', (res) => {
        const events = res.getEventsList()
        console.log(`handling ${events.length} events`)
        var count = 0
        events.forEach((event) => {
          const millis = event.getTimeInMillis()
          if (!this.lastSince || millis > this.lastSince) {
            this.lastSince = millis
          }
          const trackerId = event.getTrackerId()
          const data = {
            lowBattery: event.getBatteryStatus() == BatteryStatus.BATTERY_STATUS_LOW
          }
          data.time = new Date(millis)

          const delay = event.getDelayTimeInMillis()
          data.delay = delay ? delay / 1000 : null

          const pos = event.getPosition()
          if (pos) {
            data.lat = pos.getLatitude()
            data.lng = pos.getLongitude()
            data.alt = pos.getAltitude()
          }
          console.log(`handling event from: ${trackerId}`, event.getType(), data)
          switch (event.getType()) {
            case EventType.START_TRACKING:
              this.activity.start(trackerId, data)
              break
            case EventType.STOP_TRACKING:
              this.activity.stop(trackerId, data)
              break
            case EventType.TEXT_MESSAGE:
              data.msg = event.getMessage()
              this.activity.addMessage(trackerId, data)
              break
            case EventType.POSITION:
              this.activity.addPosition(trackerId, data)
              break
            case EventType.HEART_BEAT:
              this.activity.addHeartBeat(trackerId, data)
              break
            default:
              console.log(`Unknown event type: ${event.getType()}`)
              return
          }
          if (event.getDisplayName()) {
            this.activity.setDisplayName(trackerId, event.getDisplayName())
          }
          count++
        })
        console.log(`handled ${count} events`)
        if (this.first) {
          this.first = false
          if (this.center) {
            const splits = this.center.split(',')
            if (splits.length > 1) {
              const lng = splits[1]
              const lat = splits[0]
              if (this.zoom) {
                this.flyTo({ lng, lat }, this.zoom)
              } else {
                this.flyTo({ lng, lat })
              }
              this.viewMode = null
            }
          } else if (this.trackers.length == 1 && this.trackers[0].lastLocation) {
            this.trackers[0].following = true
            this.viewMode = ViewModes.FOLLOWING
          } else {
            this.bounding = this.activity
            this.viewMode = ViewModes.FIT_BOUNDS
          }
          this.activity.setVisible(true)
        }
        switch (this.viewMode) {
          case ViewModes.FIT_BOUNDS:
            this.applyBounds()
            break
          case ViewModes.FOLLOWING:
            for (var i = 0; i < this.trackers.length; i++) {
              const tracker = this.trackers[i]
              if (!tracker.following) {
                continue
              }
              var zoom = this.map.getZoom()
              if (zoom < preferedZoom) {
                zoom = preferedZoom
              }
              this.flyTo(tracker.lastLocation, zoom)
              break
            }
            break
          default:
            break
        }
      })
      events.on('status', (status) => {
        console.log('status', status)
      })
      events.on('end', (end) => {
        console.log('end', end)
        setTimeout(this.getEvents, 5000)
      })
      this.map.on('zoomend', () => {
        const zoom = this.map.getZoom()
        console.log('Zoom: ' + zoom)
      })
    },
    applyBounds: function () {
      const bounds = this.bounding.getBounds()
      if (!bounds) return
      this.map.fitBounds(bounds, { padding: 100, maxZoom: 16 })
    },
    flyTo: function (point, zoom = null) {
      if (zoom) {
        this.map.flyTo({ center: [point.lng, point.lat], zoom: zoom })
      } else {
        this.map.flyTo({ center: [point.lng, point.lat] })
      }
    },
    fitBounds: function (bounding) {
      this.viewMode = ViewModes.FIT_BOUNDS
      this.trackers.forEach((tracker) => {
        tracker.following = false
      })
      this.bounding = bounding
      this.applyBounds()
    },
    toggleFollow: function (tracker) {
      if (!tracker.following) {
        this.viewMode = ViewModes.FOLLOWING
        var zoom = this.map.getZoom()
        if (zoom < preferedZoom) {
          zoom = preferedZoom
        }
        this.flyTo(tracker.lastLocation, zoom)
        this.trackers.forEach((t) => {
          t.following = t == tracker
        })
      } else {
        tracker.following = false
        this.viewMode = ViewModes.FIT_BOUNDS
      }
    },
    disableViewMode() {
      this.viewMode = ViewModes.DISABLED
      this.trackers.forEach((tracker) => {
        tracker.following = false
      })
      this.bounding = null
    },
    setMessages(from, messages) {
      this.messagesFrom = from
      this.messages.splice(0)
      this.messages.push(...messages)
    },
    enterFullscreen: function () {
      const elem = document.getElementById("map_container")
      if (elem.requestFullscreen) {
        elem.requestFullscreen()
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        elem.webkitRequestFullscreen()
      } else if (elem.msRequestFullscreen) { /* IE11 */
        elem.msRequestFullscreen()
      }
    },
    exitFullscreen: function () {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.webkitExitFullscreen) { /* Safari */
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) { /* IE11 */
        document.msExitFullscreen()
      }
    },
    durationString: function (sec) {
      return durationString(sec)
    },
    onNotificationPopulated: function () {
      if (window.location.hash == '#alerts') {
        const el = document.getElementById('alertModal')
        const modal = new bootstrap.Modal(el, {})
        el.addEventListener('hidden.bs.modal', () => {
          window.location.hash = ''
        })
        modal.show()
      } else {
        console.log(window.location.hash)
      }
    },
    onNotificationChanged: function (value) {
      this.activity.notification = value
    },
    createWaypoint: function(wpt) {
      this.waypoint = wpt
      this.waypointModal.show()
    },
    editWaypoint: function (wpt) {
      this.waypoint = wpt
      this.waypointModal.show()
    },
    onWaypointCreated: function (wpt) {
      this.activity.addWaypoint(wpt, this.adminConsole)
      this.flyTo(wpt)
      this.waypointModal.hide()
    },
    onWaypointUpdated: function (wpt) {
      if (this.activity.updateWaypoint(wpt, this.adminConsole)) {
        this.flyTo(wpt)
        this.waypointModal.hide()
      }
    },
    onWaypointCanceled: function (wpt) {
      this.activity.restoreWaypoint(wpt)
    },
    onWaypointDeleted: function (wpt) {
      this.activity.deleteWaypoint(wpt)
      this.waypointModal.hide()
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
#sidebar {
  height: 100%;
}

#map_container {
  height: 100%;
}

#map {
  height: 100%;
}

#menu {
  position: absolute;
  background: #efefef80;
  padding: 5px;
  font-family: 'Open Sans', sans-serif;
  z-index: 1;
}

.waypoint {
  background-image: url('https://maps.google.com/mapfiles/kml/paddle/red-circle.png');
  background-size: 32px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  z-index: 4;
}

.last-location {
  background-image: url('https://www.google.com/mapfiles/arrow.png');
  background-size: cover;
  width: 23px;
  height: 34px;
  cursor: pointer;
  z-index: 4;
}

.start-tracking {
  background-image: url('https://maps.google.com/mapfiles/kml/paddle/go-lv.png');
  width: 16px;
  height: 16px;
  cursor: pointer;
  z-index: 3;
}

.stop-tracking {
  background-image: url('https://maps.google.com/mapfiles/kml/paddle/blu-square-lv.png');
  width: 16px;
  height: 16px;
  cursor: pointer;
  z-index: 2;
}

.message {
  background-image: url('http://maps.google.com/mapfiles/kml/shapes/post_office.png');
  background-size: 24px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  z-index: 5;
}

.mapboxgl-popup {
  z-index: 7;
}

.position {
  background-image: url('https://www.google.com/mapfiles/zoom-plus-mini.png');
  width: 18px;
  height: 18px;
  cursor: pointer;
  z-index: 1;
}

.crosshair {
  background-image: url('../assets/crosshair.png');
  width: 32px;
  height: 32px;
  /* cursor: pointer; */
  z-index: 6;
}

.btn-toggle {
  /* padding: .25rem .5rem; */
  /* font-weight: 600; */
  color: rgba(0, 0, 0, .65);
  background-color: transparent;
  /* line-height: 1.1; */
}

/* .btn-toggle:hover,
.btn-toggle:focus {
  color: rgba(0, 0, 0, .85);
  background-color: #d2f4ea;
} */
.btn-toggle::before {
  width: 1.25em;
  line-height: 0;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
  transition: transform .35s ease;
  transform-origin: .5em 50%;
}

.btn-toggle[aria-expanded="true"] {
  color: rgba(0, 0, 0, .85);
}

.btn-toggle[aria-expanded="true"]::before {
  transform: rotate(90deg);
}

.btn-toggle-nav a:hover,
.btn-toggle-nav a:focus {
  background-color: #d2f4ea;
}

/* Rules for sizing the icon. */
.material-icons.md-18 {
  font-size: 18px;
}

.material-icons.md-24 {
  font-size: 24px;
}

.material-icons.md-36 {
  font-size: 36px;
}

.material-icons.md-48 {
  font-size: 48px;
}

/* Rules for using icons as black on a light background. */
.material-icons.md-dark {
  color: rgba(0, 0, 0, 0.54);
}

.material-icons.md-dark.md-inactive {
  color: rgba(0, 0, 0, 0.26);
}

/* Rules for using icons as white on a dark background. */
.material-icons.md-light {
  color: rgba(255, 255, 255, 1);
}

.material-icons.md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3);
}
</style>
