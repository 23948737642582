import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import App from './App.vue'
import MapView from './components/MapView.vue'
import LandingPage from './views/LandingPage.vue'
import AuthCallback from './views/AuthCallback.vue'
import user from './plugins/user'

const routes = [
  { path: '/', component: LandingPage },
  { path: '/MoRE', redirect: '/IRCABR4j4sAAAA' },
  { path: '/more_all', redirect: '/IRCABR4j4sAAAA' },
  { path: '/more_staffs', redirect: '/IRCABQWFlMAAAA' },
  { path: '/more_teams', redirect: '/IRCABJnYocAAAA' },
  { path: '/more_test', redirect: '/IRCABQ0TlMAAAA' },
  { path: '/more_d2', redirect: '/IRCABRgFxcAAAA' },
  { path: '/more_d2_2', redirect: '/IRCABJVCocAAAA' },
  { path: '/more_d3', redirect: '/IRCABRDLKcAAAA' },
  { path: '/more_public', redirect: '/IRCABZB-XMAAAA' },
  { path: '/LuoDong', redirect: '/IRCABc0BTcAAAA' },
  { path: '/TOSPA', redirect: '/IRCABM0BTcAAAA' },
  { path: '/BeiBei', redirect: '/IRCABdvN30AAAA' },
  { path: '/beibei', redirect: '/IRCABdvN30AAAA' },
  { path: '/derek', redirect: '/IRCABdOHnMAAAA' },
  { path: '/OSL', redirect: '/IRCABC0BTcAAAA' },
  { path: '/8000ers', redirect: '/IRCABJtfpsAAAA' },
  { path: '/:shareCode(IRC[A-Za-z0-9-_]{11})', component: MapView, props: route => ({center: route.query.c, zoom: route.query.z, alerts: route.query.z}) },
  { path: '/authcb', component: AuthCallback, props: route => ({error: route.query.error, state: route.query.state, code: route.query.code}) },
]

const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes, // short for `routes: routes`
})
createApp(App).use(router).use(user).mount('#app')
