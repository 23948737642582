/**
 * @fileoverview gRPC-Web generated client stub for api
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: tracker.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.api = require('./tracker_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.api.TrackerServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.api.TrackerServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.RegisterInReachRequest,
 *   !proto.api.RegisterInReachResponse>}
 */
const methodDescriptor_TrackerService_RegisterInReach = new grpc.web.MethodDescriptor(
  '/api.TrackerService/RegisterInReach',
  grpc.web.MethodType.UNARY,
  proto.api.RegisterInReachRequest,
  proto.api.RegisterInReachResponse,
  /**
   * @param {!proto.api.RegisterInReachRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.RegisterInReachResponse.deserializeBinary
);


/**
 * @param {!proto.api.RegisterInReachRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.RegisterInReachResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.RegisterInReachResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.TrackerServiceClient.prototype.registerInReach =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.TrackerService/RegisterInReach',
      request,
      metadata || {},
      methodDescriptor_TrackerService_RegisterInReach,
      callback);
};


/**
 * @param {!proto.api.RegisterInReachRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.RegisterInReachResponse>}
 *     Promise that resolves to the response
 */
proto.api.TrackerServicePromiseClient.prototype.registerInReach =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.TrackerService/RegisterInReach',
      request,
      metadata || {},
      methodDescriptor_TrackerService_RegisterInReach);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.UpdateTrackerRequest,
 *   !proto.api.UpdateTrackerResponse>}
 */
const methodDescriptor_TrackerService_UpdateTracker = new grpc.web.MethodDescriptor(
  '/api.TrackerService/UpdateTracker',
  grpc.web.MethodType.UNARY,
  proto.api.UpdateTrackerRequest,
  proto.api.UpdateTrackerResponse,
  /**
   * @param {!proto.api.UpdateTrackerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.UpdateTrackerResponse.deserializeBinary
);


/**
 * @param {!proto.api.UpdateTrackerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.UpdateTrackerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.UpdateTrackerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.TrackerServiceClient.prototype.updateTracker =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.TrackerService/UpdateTracker',
      request,
      metadata || {},
      methodDescriptor_TrackerService_UpdateTracker,
      callback);
};


/**
 * @param {!proto.api.UpdateTrackerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.UpdateTrackerResponse>}
 *     Promise that resolves to the response
 */
proto.api.TrackerServicePromiseClient.prototype.updateTracker =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.TrackerService/UpdateTracker',
      request,
      metadata || {},
      methodDescriptor_TrackerService_UpdateTracker);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.ListEventRequest,
 *   !proto.api.ListEventResponse>}
 */
const methodDescriptor_TrackerService_ListEvent = new grpc.web.MethodDescriptor(
  '/api.TrackerService/ListEvent',
  grpc.web.MethodType.UNARY,
  proto.api.ListEventRequest,
  proto.api.ListEventResponse,
  /**
   * @param {!proto.api.ListEventRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.ListEventResponse.deserializeBinary
);


/**
 * @param {!proto.api.ListEventRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.ListEventResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.ListEventResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.TrackerServiceClient.prototype.listEvent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.TrackerService/ListEvent',
      request,
      metadata || {},
      methodDescriptor_TrackerService_ListEvent,
      callback);
};


/**
 * @param {!proto.api.ListEventRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.ListEventResponse>}
 *     Promise that resolves to the response
 */
proto.api.TrackerServicePromiseClient.prototype.listEvent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.TrackerService/ListEvent',
      request,
      metadata || {},
      methodDescriptor_TrackerService_ListEvent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.ReplayEventRequest,
 *   !proto.api.ReplayEventResponse>}
 */
const methodDescriptor_TrackerService_ReplayEvent = new grpc.web.MethodDescriptor(
  '/api.TrackerService/ReplayEvent',
  grpc.web.MethodType.UNARY,
  proto.api.ReplayEventRequest,
  proto.api.ReplayEventResponse,
  /**
   * @param {!proto.api.ReplayEventRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.ReplayEventResponse.deserializeBinary
);


/**
 * @param {!proto.api.ReplayEventRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.api.ReplayEventResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.api.ReplayEventResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.api.TrackerServiceClient.prototype.replayEvent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/api.TrackerService/ReplayEvent',
      request,
      metadata || {},
      methodDescriptor_TrackerService_ReplayEvent,
      callback);
};


/**
 * @param {!proto.api.ReplayEventRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.api.ReplayEventResponse>}
 *     Promise that resolves to the response
 */
proto.api.TrackerServicePromiseClient.prototype.replayEvent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/api.TrackerService/ReplayEvent',
      request,
      metadata || {},
      methodDescriptor_TrackerService_ReplayEvent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.api.StreamEventRequest,
 *   !proto.api.StreamEventResponse>}
 */
const methodDescriptor_TrackerService_StreamEvent = new grpc.web.MethodDescriptor(
  '/api.TrackerService/StreamEvent',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.api.StreamEventRequest,
  proto.api.StreamEventResponse,
  /**
   * @param {!proto.api.StreamEventRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.api.StreamEventResponse.deserializeBinary
);


/**
 * @param {!proto.api.StreamEventRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.api.StreamEventResponse>}
 *     The XHR Node Readable Stream
 */
proto.api.TrackerServiceClient.prototype.streamEvent =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/api.TrackerService/StreamEvent',
      request,
      metadata || {},
      methodDescriptor_TrackerService_StreamEvent);
};


/**
 * @param {!proto.api.StreamEventRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.api.StreamEventResponse>}
 *     The XHR Node Readable Stream
 */
proto.api.TrackerServicePromiseClient.prototype.streamEvent =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/api.TrackerService/StreamEvent',
      request,
      metadata || {},
      methodDescriptor_TrackerService_StreamEvent);
};


module.exports = proto.api;

